import React, { Component } from "react";
import CarouselSection from './carousel-section.jsx';
import VideoItem from './video-item.jsx';
import Magnific from './magnific.jsx';

class VideosCarousel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            modalIndex: 0
        };

        this.handleVideoClick = this.handleVideoClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    get galleryItems() {
        return this.props.items.map(v => {
            return {
                type: v.url.indexOf('.mp4') >= 0 ? 'video' : 'iframe',
                src: v.url,
                description: v.title
            };
        });
    }


    handleVideoClick(e) {
        e.preventDefault();

        let link = e.target;
        while (link.tagName !== 'A') {
            link = link.parentElement;
        }
        console.log(link);
        this.setState({
            showModal: true,
            modalIndex: +link.getAttribute('data-index')
        });
    }

    handleClose() {
        this.setState({
            showModal: false
        });
    }

    render() {
        if (this.props.items.length) {
            return (
                <React.Fragment>
                    <CarouselSection title={this.props.title} titleBold={this.props.titleBold} className={this.props.sectionClass} titleClassName={this.props.sectionTitleClass}>
                        {this.props.items.map((video, index) => (
                            <VideoItem key={index} {...video} onClick={this.handleVideoClick} colClass={this.props.colClass} index={index} colClass="col-lg-4 col-sm-6" />
                        ))}
                    </CarouselSection>

                    <Magnific items={this.galleryItems} show={this.state.showModal} onClose={this.handleClose} index={this.state.modalIndex} />
                </React.Fragment>
            );
        }

        return null;
    }
}

export default VideosCarousel;