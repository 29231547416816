import React, { useState, useEffect } from 'react';
import { updateDictionary } from '../../../../shared/utils/index.js';
import ProgressBar from './progress-bar.jsx';
import Question from './question.jsx';
import NavigationButton from './navigation-button.jsx';
import CustomContentItem from '../custom-content-item.jsx';

const StepCard = props => {
    const [inputCollection, setInputCollection] = useState([]);
    const [isValid, setIsValid] = useState(true);
    const [userInteracted, setUserInteracted] = useState(false);

    useEffect(() => {
        if (userInteracted) {
            setIsValid(checkInputs());
        }
    }, [inputCollection]);

    const handleButtonClick = (callback = () => { }, skipValidation) => {
        if (skipValidation) {
            setInputCollection([]);
            setIsValid(true);
            setUserInteracted(false);
            callback();
        } else {
            setUserInteracted(true);

            if (checkInputs()) {
                callback();
                setIsValid(true);
                setUserInteracted(false);
                setInputCollection([]);
            } else {
                setIsValid(false);
            }
        }
    }

    const handleInputChange = (e) => {
        props.onChange(e);
    }

    const handleInputValidate = (id, value) => {
        setInputCollection(arr => updateDictionary(arr, id, value))
    };

    const checkInputs = () => {
        return inputCollection.every((item) => { return item.value == true });
    };

    return (
        <>
            {props.step &&
                <div className="container">
                    <div className="text-center cft-step-card">
                        {props.step.showProgressBar && <ProgressBar progress={props.progress} />}
                        <div className="row header-row">
                            {props.step.bannerImageUrl && <div className="col-12"><img className="banner-image" src={props.step.bannerImageUrl} /></div>}
                            {props.step.headerImageUrl && <div className="col-lg"><img className="header-image" src={props.step.headerImageUrl} /></div>}
                            <div className={props.step.headerImageUrl ? 'col-lg-10': 'col-12'}>
                                <div className="header-text">
                                    {props.step.primaryTitle && <div className="primary-title" >{props.step.primaryTitle}</div>}
                                    {props.step.primaryCopy && <div className="primary-copy">{props.step.primaryCopy}</div>}
                                    {props.step.secondaryTitle && <div className="secondary-title" >{props.step.secondaryTitle}</div>}
                                    {props.step.secondaryCopy && <div className="secondary-copy" >{props.step.secondaryCopy}</div>}
                                </div>
                            </div>
                        </div>
                        {props.step.questions && props.step.questions.length > 0 && 
                            <div className="row question-row">
                                {props.step.questions && props.step.questions?.map((item, index) => {
                                    return <Question key={item.id} question={item} currentContact={props.currentContact} userId={props.userId} userSelections={props.userSelections} selectedValue={props.selectedStepValues?.find(data => data.questionId === item.id)?.value} cssClass={props.step.isTwoColumn ? 'col-lg-6' : 'col-12'} showValidation={!isValid} onChange={handleInputChange} onValidate={(e) => handleInputValidate(item.id, e)} />
                                })}
                            </div>
                        }
                        {props.step.navigationButtons && props.step.navigationButtons.length > 0 &&
                            <div className="row cft-nav-controls">
                                {props.step.navigationButtons?.map((item, index) => {
                                    return (
                                        <NavigationButton key={item.id} button={item} isValid={isValid} onNext={() => handleButtonClick(props.onNext)} onPrevious={() => handleButtonClick(props.onPrevious, true)} onJump={(id) => handleButtonClick(props.onJump(id), true)} onSubmit={(id, callback) => handleButtonClick(() => props.onSubmit(id, callback))} />
                                    );
                                })}
                            </div>}
                    </div>

                    {props.step.featuredItems &&
                        <div className="row">
                            {props.step.featuredItems.map((item, index) => {
                                return (<CustomContentItem key = {index} {...item} cardType={false} />);
                            })}
                        </div>
                    }
                </div>
            }
        </>
    );
}

export default StepCard;