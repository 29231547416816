import { createElement } from 'react';
import { render } from 'react-dom';
import { getProductsForSale } from "../apis/custom-content.js"
import { getProductsForSaleFilters } from "../apis/filters.js"

export default class ProductsForSaleContainer {
    constructor() {
        this.containers = document.querySelectorAll('.js-products-for-sale');

        if (this.containers.length) {
            this.containers.forEach((item) => {
                this.start(item);
            });
        }
    }

    start(item) {
        const api = getProductsForSale,
              filterApi = getProductsForSaleFilters;

        import('../react/components/products-for-sale-widget.jsx')
            .then(ProductsForSale => {
                render(createElement(ProductsForSale.default, { api, filterApi }), item);
            });
    }
}