import React, { Component } from "react";
import BlogItem from './blog-item.jsx';
import CarouselSectionSwitcher from './carousel-section-switcher.jsx';
import { getArticles, getCustomArticles } from '../../apis/inspiration';

class InspirationSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            isLoading: true
        };
    }

    componentDidMount() {

        if (this.props.inspiration[0] !== '' && this.props.inspiration !== null) {

            let params = {
                ids: this.props.inspiration
            }
            getCustomArticles(params)
                .then(data => {            
                        this.setState({
                            items: data,
                            isLoading: false
                        })                 
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        isLoading: false
                    });
                });
        }
        else {
            getArticles({
                page: 1,
                pageSize: 3
            })
                .then(data => {
                    this.setState({
                        items: data.records,
                        isLoading: false
                    });
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        isLoading: false
                    });
                });
        }
    }


    render() {
        if (this.state.items.length || this.state.isLoading) {
            const carouselItems = this.state.items.map((blog, index) => (
                <BlogItem key={index} {...blog} colClass={this.props.colClass} />
            ));
            const sectionItems = <div className="row">
                {this.state.items.map((blog, index) => (
                    <BlogItem key={index} {...blog} colClass={this.props.colClass} />
                ))}
            </div>;

            return (
                <CarouselSectionSwitcher switchWidth={575} title={this.props.title} titleBold={this.props.titleBold} carousel={carouselItems} section={sectionItems} showLoader={this.state.isLoading} />
            );
        }

        return null;
    }
}

export default InspirationSection;