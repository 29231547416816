import React, { Component } from "react";
import Lazyload from './lazyload.jsx';
import CSSTransition from 'react-transition-group/CSSTransition';

export default class MosaicItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imageVisible: false
        };

        this.handleLazyloadVisible = this.handleLazyloadVisible.bind(this);
    }

    handleLazyloadVisible() {
        this.setState({
            imageVisible: true
        });
    }

    render() {
        let width = `span${this.props.width}`,
            url = this.props?.PageData?.PageUrl ? this.props.PageData.PageUrl : this.props.ExternalUrl ,
            target,
            readMore,
            videoPlay,
            subtitle;
        if (this.props.PageVideoData) {
            videoPlay = <svg className="icon icon-play mosaic-item-play"><use xlinkHref="#icon-play"></use></svg>;
            url = this.props.PageVideoData.Url;
            target = '_blank';
            readMore = <a className="mosaic-item-link" href={url} data-index={this.props.index} target={target} onClick={this.props.handleClick} tabIndex="-1">Watch ></a>;
        }
        else {
            readMore = <a className="mosaic-item-link" href={url} data-index={this.props.index} onClick={this.props.handleClick} tabIndex="-1">Read More ></a>;
        }
        if (this.props.Subtitle) subtitle = <span className="mosaic-item-subtitle">{this.props.Subtitle}</span>;

        return (
            <article className={`mosaic-item ${width}`}>
                <div className="mosaic-item-img-container">
                    <CSSTransition in={this.state.imageVisible} timeout={250} classNames="fade">
                        <Lazyload height="100%" rootMargin="100px" onVisible={this.handleLazyloadVisible}>
                            <a href={url} onClick={this.props.handleClick} target={target} data-index={this.props.index}>
                                <picture>
                                    <source srcSet={`${this.props.Image992} 1x, ${this.props.Image992HighRes} 2x`} media="(min-width: 992px)" />
                                    <source srcSet={`${this.props.Image768} 1x, ${this.props.Image768HighRes} 2x`} media="(min-width: 768px)" />
                                    <source srcSet={`${this.props.Image576} 1x, ${this.props.Image576HighRes} 2x`} media="(min-width: 576px)" />
                                    <img srcSet={`${this.props.Image0} 1x, ${this.props.Image0HighRes} 2x`} alt={this.props.ImageAlt ? this.props.ImageAlt : this.props.PageData.DocumentName} />
                                </picture>
                            </a>
                        </Lazyload>
                    </CSSTransition>
                    {videoPlay}
                </div>
                <div className="mosaic-item-content">
                    <a className="mosaic-item-content-link" href={url} data-index={this.props.index} onClick={this.props.handleClick} aria-hidden="true" tabIndex="-1"></a>
                    {subtitle}
                    <h3 className="mosaic-item-title"><a href={url} data-index={this.props.index} onClick={this.props.handleClick}>{this.props.Title ? this.props.Title : this.props.PageData.DocumentName}</a></h3>
                    <p className="mosaic-item-text">{this.props.Text}</p>
                    {readMore}
                </div>
            </article>
        );
    }
}