import React, { Component } from "react";
import ReactDOM from 'react-dom';
import '../../../../scss/site/components/magnific.scss';

class Magnific extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: props.show,
            index: props.index,
            items: this.generateItems(props.items)
        };

        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
        this.close = this.close.bind(this);
        this.handleKeyup = this.handleKeyup.bind(this);
        this.trapFocus = this.trapFocus.bind(this);

        this.wrap = React.createRef();
        this.focusEls = [];
        this.ignoreFocusChanges = false;
        this.twentyFive = false;
        this.fifty = false
        this.seventyFive = false;
    }

    get currentItem() {
        // console.log('getting item');
        if (!this.length) return {};
        return this.state.items[this.state.index];
    }

    get isImage() {
        return !this.currentItem.type || this.currentItem.type === 'image';
    }

    get isIframe() {
        return this.currentItem.type === 'iframe';
    }

    get isVideo() {
        return this.currentItem.type === 'video';
    }

    get isInline() {
        return this.props.type === 'inline';
    }

    get isLoaded() {
        return this.currentItem.isLoaded;
    }

    get isError() {
        return this.currentItem.isError;
    }

    get length() {
        return this.state.items.length;
    }

    get scrollbarSize() {
        let scrollDiv = document.createElement("div");
        scrollDiv.style.cssText = 'width: 99px; height: 99px; overflow: scroll; position: absolute; top: -9999px;';
        document.body.appendChild(scrollDiv);
        let size = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        document.body.removeChild(scrollDiv);
        return size;
    }

    generateItems(items) {
        // console.log('items generated');
        return (items || this.state.items).map(i => {
            const state = {
                isError: false,
                isLoaded: i.type && i.type !== 'image'
            };
            if (!state.isLoaded) state.isLoading = true;
            return Object.assign({}, i, state);
        });
    }

    componentDidMount() {
        this.body = document.querySelector('body');
        this.html = document.querySelector('html');

        if (this.props.show) this.preload();

        if (FeaturesEnabled.GoogleTagManager && this.isVideo) {

            console.log(dataLayer);

            let video = document.querySelector('.mfp-video');

            if (video) {
                video.addEventListener('loadstart', () => this.startVideo(video));

                this.updatePercentage(video);

                video.addEventListener('ended', () => this.videoComplete(video));

                video.addEventListener('pause', () => this.pauseVideo(video));
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.wrap.current) this.focusEls = this.wrap.current.querySelectorAll('a, button, input, textarea, select');

        if (this.state.isOpen !== prevState.isOpen) {
            if (this.state.isOpen) {
                if (this.isImage) {
                    // console.log('updated - preload');
                    this.preloadImage(this.currentItem, () => {
                        // console.log('preloaddddding');
                        if (this.length > 1) this.preload();
                    });
                }

                if (FeaturesEnabled.GoogleTagManager && this.isVideo) {

                    console.log(dataLayer);

                    let video = document.querySelector('.mfp-video');

                    video.addEventListener('loadstart', () => this.startVideo(video));

                    this.updatePercentage(video);

                    video.addEventListener('ended', () => this.videoComplete(video));

                    video.addEventListener('pause', () => this.pauseVideo(video));
                }

                this.lastWindowY = document.documentElement.scrollTop;
                this.body.classList.add('mfp-zoom-out-cur');
                this.html.style.overflow = 'hidden';
                this.html.style.marginRight = this.scrollbarSize + 'px';
                // focus on the first close
                if (this.isInline) {
                    const close = this.wrap.current.querySelector('.mfp-close');
                    close.focus();
                    this.lastFocus = close;
                }
                else {
                    const next = this.wrap.current.querySelector('.mfp-arrow-right');
                    if (next) {
                        next.focus();
                        this.lastFocus = next;
                    }
                }
                document.addEventListener('keyup', this.handleKeyup);
                document.addEventListener('focus', this.trapFocus, true);
            }
            else {
                this.body.classList.remove('mfp-zoom-out-cur');
                this.html.style.overflow = '';
                this.html.style.marginRight = '';
                document.removeEventListener('keyup', this.handleKeyup);
                document.removeEventListener('focus', this.trapFocus, true);
            }
        }

        if (this.state.index !== prevState.index && this.isImage) {
            this.preload();
        }
    }

    componentWillReceiveProps(nextProps) {
        const state = {
            isOpen: nextProps.show,
            items: nextProps.items
        };

        // update items and status if re-loaded
        if (JSON.stringify(this.props.items) !== JSON.stringify(nextProps.items)) {
            state['items'] = this.generateItems(nextProps.items);
        }

        if (this.props.index !== nextProps.index && nextProps.index + 1 <= nextProps.items.length && nextProps.index >= 0) {
            state['index'] = nextProps.index;
        }

        this.setState(state);
    }

    prev(e) {
        if (e) e.stopPropagation();
        if (this.state.index === 0) this.setState({ index: this.length - 1 });
        else this.index = this.setState({ index: this.state.index - 1 });
    }

    next(e) {
        if (e) e.stopPropagation();
        if (this.state.index + 1 === this.length) this.setState({ index: 0 });
        else this.setState({ index: this.state.index + 1 });
    }

    containerClasses() {
        return [
            'mfp-container',
            this.isImage ? 'mfp-image-holder' : '',
            this.isIframe ? 'mfp-iframe-holder' : '',
            this.isVideo ? 'mfp-video-holder' : '',
            this.isInline ? 'mfp-inline-holder' : '',
            this.isLoaded ? 'mfp-s-ready' : '',
            this.isError ? 'mfp-s-error' : ''
        ].join(' ');
    }

    parseIframeSrc(src) {
        if (!src) return '';

        const patterns = {
            youtube: {
                index: 'youtube.com',
                id: 'v=',
                src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0'
            },
            youtubeShort: {
                index: 'youtu.be',
                id: '/',
                src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0'
            },
            vimeo: {
                index: 'vimeo.com/',
                id: '/',
                src: '//player.vimeo.com/video/%id%?autoplay=1'
            },
            widen: {
                index: 'vollrath.widen',
                id: '/',
                src: src
            }
        };
        let id = '',
            keyMatch = '';

        for (var key in patterns) {
            if (src.indexOf(patterns[key].index) >= 0) {
                keyMatch = key;
                id = src.substr(src.lastIndexOf(patterns[key].id) + patterns[key].id.length, src.length);
                id = id.split('&')[0];
                break;
            }
        }

        return patterns[keyMatch].index === 'vollrath.widen' ? patterns[keyMatch].src : patterns[keyMatch].src.replace('%id%', id);
    }

    handleKeyup(e) {
        switch (e.keyCode) {
            // esc key
            case 27:
                this.close();
                break;
            // right arrow
            case 39:
                this.next();
                break;
            // left arrow
            case 37:
                this.prev();
                break;
        }
    }

    close() {
        this.props.onClose();
        this.setState({
            isOpen: false
        });
    }

    stopProp(e) {
        e.stopPropagation();
    }

    preload() {
        let nextItem,
            prevItem;
        if (this.state.index + 1 === this.length) nextItem = this.state.items[0];
        else nextItem = this.state.items[this.state.index + 1];
        if (this.state.index - 1 < 0) prevItem = this.state.items[this.length - 1];
        else prevItem = this.state.items[this.state.index - 1];

        this.preloadImage(nextItem, () => {
            this.preloadImage(prevItem);
        });
    }

    preloadImage(item, callback) {
        if (!item.isImage) return;

        const self = this,
            index = this.state.items.indexOf(item);
        item = Object.assign({}, item);

        if (!item.isPreloaded) {
            const img = new Image();
            item.isPreloaded = true;
            item.isLoading = true;

            function load() {
                img.removeEventListener('load', load, false);
                img.removeEventListener('error', error, false);
                item.isLoaded = true;
                delete item.isLoading;
                self.setState({
                    items: self.updateItemStatus(item, index)
                });
            }

            function error() {
                img.removeEventListener('load', load, false);
                img.removeEventListener('error', error, false);
                item.isError = true;
                delete item.isLoading;
                self.setState({
                    items: self.updateItemStatus(item, index)
                });
            }

            img.addEventListener('load', load, false);
            img.addEventListener('error', error, false);
            img.src = item.src;
            if (img.loaded) load();
            if (callback) callback();
        }
    }

    updateItemStatus(item, index) {
        const items = this.state.items.map(i => {
            return Object.assign({}, i);
        });
        items[index] = item;
        return items;
    }

    startVideo(video) {
        console.log('start');
        console.log(this.currentItem.description);
        dataLayer.push({
            'event': 'Kentico_Video',
            'gtm.videoTitle': this.currentItem.description,
            'gtm.videoStatus': 'start',
        });
    }

    pauseVideo(video) {
        console.log('pause');
        console.log(this.currentItem.description);
        dataLayer.push({
            'event': 'Kentico_Video',
            'gtm.videoTitle': this.currentItem.description,
            'gtm.videoStatus': 'pause',
        });
    }

    updatePercentage(video) {

        let x = setInterval(() => {
            if ((video.currentTime === video.duration) || !this.state.isOpen) {
                clearInterval(x)
                this.twentyFive = false;
                this.fifty = false;
                this.seventyFive = false;
            } else {
                let percentage = Math.round((video.currentTime / video.duration) * 100);

                if (percentage >= 25 && percentage < 50 && !this.twentyFive) {
                    console.log("Got to 25%");
                    console.log(this.currentItem.description);
                    dataLayer.push({
                        'event': 'Kentico_Video',
                        'gtm.videoTitle': this.currentItem.description,
                        'gtm.videoStatus': 'progress',
                        'gtm.videoPercent': '25'

                    });
                    this.twentyFive = true;
                }

                if (percentage >= 50 && percentage < 75 && !this.fifty) {

                    console.log("Got to 50%");
                    dataLayer.push({
                        'event': 'Kentico_Video',
                        'gtm.videoTitle': this.currentItem.description,
                        'gtm.videoStatus': 'progress',
                        'gtm.videoPercent': '50'

                    });
                    this.fifty = true;
                }

                if (percentage >= 75 && !this.seventyFive) {
                    console.log("Got to 75%");
                    dataLayer.push({
                        'event': 'Kentico_Video',
                        'gtm.videoTitle': this.currentItem.description,
                        'gtm.videoStatus': 'progress',
                        'gtm.videoPercent': '75'
                    });
                    this.seventyFive = true;

                }
            }

        })

    }

    videoComplete(video) {
        console.log('complete');
        dataLayer.push({
            'event': 'Kentico_Video',
            'gtm.videoTitle': this.currentItem.description,
            'gtm.videoStatus': 'complete',
        });
    }

    trapFocus(e) {
        if (this.ignoreFocusChanges || !this.wrap.current) return;

        if (this.wrap.current.contains(e.target)) {
            this.lastFocus = e.target;
        }
        else {
            this.ignoreFocusChanges = true;
            this.focusEls[0].focus();
            if (this.lastFocus === document.activeElement) {
                this.focusEls[this.focusEls.length - 1].focus();
            }
            this.ignoreFocusChanges = false;
            this.lastFocus = document.activeElement;
            window.scrollTo(0, this.lastWindowY);
        }
    }

    renderPortal() {
        let modal,
            modalInner,
            inline,
            counter,
            captions;

        const currentItem = this.currentItem;
        const isLoaded = currentItem.isLoaded || typeof currentItem.isLoaded === 'undefined' || this.isInline;

        if (this.isInline) {
            inline = <div className="mfp-modal">
                <button title="Close (Esc)" aria-label="Close (Esc)" id="product-ask-close" type="button" className="mfp-close" onClick={this.close}>×</button>
                {this.props.children}
            </div>;
        }
        if (this.length > 1) {
            counter = <div className="mfp-counter">{this.state.index + 1} of {this.length}</div>;
        }
        if (currentItem.captions) {
            captions = <track label="English" kind="subtitles" srcLang="en" src={currentItem.captions} default />;
        }
        // console.log('render', currentItem);
        if (this.state.isOpen) {
            modalInner = <div className={this.containerClasses()}>
                {isLoaded && (
                    <div className="mfp-content" onClick={this.stopProp}>
                        {this.isIframe && (
                            <div className="mfp-iframe-scaler">
                                <button title="Close (Esc)" id="magnific-iframe-close" aria-label="Close (Esc)" type="button" className="mfp-close" onClick={this.close}>×</button>
                                <figure>
                                    <iframe className="mfp-iframe" src={this.parseIframeSrc(currentItem.src)} frameBorder="0" allowFullScreen title={currentItem.description ? currentItem.description : 'Video'}></iframe>
                                    <figcaption>
                                        <div className="mfp-bottom-bar">
                                            <div className="mfp-title">
                                                {currentItem.description}
                                            </div>
                                            {counter}
                                        </div>
                                    </figcaption>
                                </figure>
                            </div>
                        )}
                        {this.isVideo && (
                            <div className="mfp-video-scaler">
                                <button title="Close (Esc)" id="magnific-video-close" aria-label="Close (Esc)" type="button" className="mfp-close" onClick={this.close}>×</button>
                                <figure>
                                    <video className="mfp-video" src={currentItem.src} poster={currentItem.poster} autoPlay controls>
                                        {captions}
                                    </video>
                                    <figcaption>
                                        <div className="mfp-bottom-bar">
                                            <div className="mfp-title">
                                                {currentItem.description}
                                            </div>
                                            {counter}
                                        </div>
                                    </figcaption>
                                </figure>
                            </div>
                        )}
                        {(this.isImage && !this.isInline) && (
                            <div className="mfp-figure">
                                <button title="Close (Esc)" id="magnific-image-close" aria-label="Close (Esc)" type="button" className="mfp-close" onClick={this.close}>×</button>
                                <figure>
                                    <img className="mfp-img product-img" src={currentItem.src} onClick={this.next} />
                                    <figcaption>
                                        <div className="mfp-bottom-bar">
                                            <div className="mfp-title">
                                                {currentItem.description}
                                            </div>
                                            {counter}
                                        </div>
                                    </figcaption>
                                </figure>
                            </div>
                        )}
                        {inline}
                    </div>
                )}
                {(currentItem.isLoading || currentItem.isError) && (
                    <div className="mfp-preloader">
                        {currentItem.isLoading && (
                            <span>Loading...</span>
                        )}
                        {currentItem.isError && (
                            <span><a href={currentItem.src}>The image</a> could not be loaded</span>
                        )}
                    </div>
                )}
                {this.length > 1 && (
                    <div>
                        <button title="Previous (Left arrow key)" type="button" className="mfp-arrow mfp-arrow-left mfp-prevent-close" onClick={this.prev}>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button title="Next (Right arrow key)" type="button" className="mfp-arrow mfp-arrow-right mfp-prevent-close" onClick={this.next}>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                )}
            </div>;
        }

        modal = <div>
            <div className={`mfp-bg ${this.state.isOpen ? '' : 'mfp-hide'}`}></div>
            <div className={`mfp-wrap mfp-gallery mfp-close-btn-in mfp-auto-cursor ${this.state.isOpen ? '' : 'mfp-hide'}`} tabIndex="-1" style={{ overflow: 'hidden auto' }} onClick={this.close} ref={this.wrap}>
                {modalInner}
            </div>
        </div>;


        return ReactDOM.createPortal(modal, document.querySelector('body'));
    }

    render() {
        return [
            <div key="base">{this.props.activator}</div>,
            this.renderPortal(),
        ];
    }
}

Magnific.defaultProps = {
    show: false,
    items: [],
    type: '',
    onClose: function () { },
    index: 0
};

export default Magnific;