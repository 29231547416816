import { createElement } from 'react';
import { render } from 'react-dom';

export default class ShoppingCart {
    constructor() {
        this.container = document.querySelector('.js-shopping-cart');

        if (this.container) {
            this.start();
        }
    }

    start() {
        const cartCopy = this.container.getAttribute('data-cart-copy'),
            user = this.container.getAttribute('data-user');
        import('../react/pages/shopping-cart.jsx')
            .then(ShoppingCart => {
                render(createElement(ShoppingCart.default, { cartCopy, user }), this.container);
            });
    }
}