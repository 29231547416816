import React, { Component } from 'react';
import SearchMenuBase from './search-menu-base.jsx';
import LoaderSection from './loader-section.jsx';

class SearchMenuMobileContainer extends SearchMenuBase {
    constructor(props) {
        super(props);

        this.handleCategoryClick = this.handleCategoryClick.bind(this);
    }

    handleCategoryClick(e) {
        this.setState({
            category: e.target.dataset.name
        });
    }

    render() {
        let loader,
            noResults;

        if (this.state.isLoading) {
            loader = <LoaderSection className="short" show={true} />;
        }

        let quickLinks = this.state.quickLinks.map((item, index) =>
            <li key={index}><a href={item.pageUrl}>{item.pageName}</a></li>
        )

        if (!this.state.isLoading &&
            this.state.productItems.length === 0 &&
            this.state.keywordItems.length === 0 &&
            this.state.documentItems.length === 0 &&
            this.state.q) {
            noResults = <p className="search-drawer-no-results">No results found</p>;
        }

        const drawer = (
            <div ref={this.searchDrawer} className={`search-drawer mobile ${this.state.isOpen ? 'open' : ''}`}>
                <div className="search-drawer-content">
                    {loader}
                    {noResults}
                    {this.state.productItems.length > 0 &&
                        <section className="search-drawer-section">
                            <h4 className="search-drawer-title">Products</h4>
                            <div className="search-drawer-section-content">
                                <ul className="search-drawer-links">
                                    {this.state.productItems.map((item, index) => (
                                        <li key={item.nodeId}><a href={item.pageUrl}>{item.skuNumber} - {item.documentName}</a></li>
                                    ))}
                                    {this.state.productItems.length === 5 &&
                                        <li><button data-name="products" onClick={this.handleCategoryClick} className="search-drawer-all-btn">View All ></button></li>
                                    }
                                </ul>
                            </div>
                        </section>
                    }
                    {this.state.keywordItems.length > 0 &&
                        <section className="search-drawer-section">
                            <h4 className="search-drawer-title">Categories</h4>
                            <div className="search-drawer-section-content">
                                <ul className="search-drawer-links">
                                    {this.state.keywordItems.map((item, index) => (
                                        <li key={item.nodeId}><a href={item.pageUrl}>{item.documentName}</a></li>
                                    ))}
                                </ul>
                            </div>
                        </section>
                    }
                    {this.state.documentItems.length > 0 &&
                        <section className="search-drawer-section">
                            <h4 className="search-drawer-title">Documents</h4>
                            <div className="search-drawer-section-content">
                                <ul className="search-drawer-links">
                                    {this.state.documentItems.map((item, index) => (
                                        <li key={item.nodeId}><a href={`/downloads/${item.fileName}`}>{item.displayName}</a></li>
                                    ))}
                                </ul>
                            </div>
                        </section>
                    }
                    {!this.state.q &&
                        <section className="search-drawer-section">
                            <h4 className="search-drawer-title">Quick Links</h4>
                            <div className="search-drawer-section-content">
                                <ul className="search-drawer-links">
                                    {quickLinks}
                                </ul>
                            </div>
                        </section>
                    }
                </div>
            </div>
        );

        return (
            <form action="/search" method="get" onSubmit={this.handleFormSubmit} className="search-field-mobile">
                <div className="search-field-mobile-container">
                    <label className="sr-only" htmlFor="search-field-mobile">Search</label>
                    <input ref={this.searchInput} className="search-field-mobile-input" id="search-field-mobile" type="text" name="q" placeholder="Search..." onChange={this.handleChange} onFocus={this.handleFocus} onBlur={this.handleBlur} required autoCapitalize="none" autoCorrect="off" />
                    <button className="search-field-mobile-btn">
                        <svg className="icon" width="30" height="30"><use xlinkHref="#icon-search"></use></svg>
                        <span className="sr-only">Search</span>
                    </button>

                    {drawer}
                </div>
            </form>
        );
    }
}

export default SearchMenuMobileContainer;