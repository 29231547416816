import { createElement } from 'react';
import { render } from 'react-dom';

export default class FindAServiceAgentContainer {
    constructor() {
        this.container = document.querySelector('.js-service-agent-root');

        if (this.container) {
            this.start();          
        }
    }

    start() {
        const id = this.container.getAttribute('data-id');
        const errorMsg = this.container.getAttribute('data-error-msg');
        const errorBtn = this.container.getAttribute('data-error-btn');
        import('../react/pages/find-a-service-agent-landing.jsx')
            .then(FindAServiceAgent => {
                render(createElement(FindAServiceAgent.default, { id, errorMsg, errorBtn }), this.container);
            });
    }
}