import jfetch from '@jontetz/jfetch';

/**
 * Get filters that are available for a product classifications products
 * @param {String} id
 * @param {Object} params
 */
export function getProductFilterOptions(id, params) {
    return jfetch.get(`/api/filter/productfilteroptions?id=${id}`, params);
}

/**
 * Get filtered product classifications products
 * @param {String} id
 * @param {Object} params
 */
export function getFilteredProductsByClassification(id, params) {
    return jfetch.get(`/api/filter/productsbyclassification?id=${id}`, params);
}

/**
 * Get filters that are available for a virtual classification
 * @param {String} id
 */
export function getClassificationFilterOptions(id, params) {
    return jfetch.get(`/api/filter/classificationfilteroptions?id=${id}`, params);
}

/**
 * Get filtered product classifications product classifications
 * @param {String} id
 * @param {Object} params
 */
export function getFilteredClassificationsByClassification(id, params) {
    return jfetch.get(`/api/filter/classificationsbyclassification?id=${id}`, params);
}

/**
 * Get filters for products for sale
 * @param {String} id
 * @param {Object} params
 */
export function getProductsForSaleFilters(params) {
    return jfetch.get(`/api/filter/productsforsaleclassifications`, params);
}

export function getForSaleAndDiscontinuedCount(params) {
    return jfetch.get(`/api/filter/productsforsaleanddiscontinuedcount`, params);
}