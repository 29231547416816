import Flickity from 'flickity';
import ProductsCarousel from '../react/components/products-carousel.jsx';
import VideosCarousel from '../react/components/videos-carousel.jsx';
import BlogsCarousel from '../react/components/blogs-carousel.jsx';
import { getProductClassificationRelatedGenus } from '../apis/product-classification';
import { getProductClassificationSiblings } from '../apis/product-classification';
import { createElement } from 'react';
import { render } from 'react-dom';

export default class Carousel {
    constructor() {
        this.carousels = document.querySelectorAll('.js-carousel');
        this.rgCarousels = document.querySelectorAll('.js-related-genus-carousel-section');
        this.rpCarousels = document.querySelectorAll('.js-related-products-carousel');
        this.productsCarousels = document.querySelectorAll('.js-products-carousel');
        this.videoCarousels = document.querySelectorAll('.js-videos-carousel');
        this.blogCarousels = document.querySelectorAll('.js-blogs-carousel');

        if (this.carousels.length) {
            this.startCarousels();
        }
        if (this.rgCarousels.length) {
            this.startRGCarousels();
        }
        if (this.rpCarousels.length) {
            this.startRPCarousels();
        }
        if (this.productsCarousels.length) {
            this.startProductsCarousels();
        }
        if (this.videoCarousels.length) {
            this.startVideoCarousels();
        }
        if (this.blogCarousels.length) {
            this.startBlogCarousels();
        }
    }

    startCarousels() {
        for (let i = 0; i < this.carousels.length; i++) {
            const carousel = this.carousels[i];
            const flkty = new Flickity(carousel, {
                // options...
                pageDots: false,
                cellAlign: 'left',
                contain: true,
                lazyLoad: 2,
                groupCells: true
            });
        }
    }

    startRGCarousels() {
        for (let i = 0; i < this.rgCarousels.length; i++) {
            const carousel = this.rgCarousels[i],
                id = carousel.getAttribute('data-id'),
                title = carousel.getAttribute('data-title'),
                titleBold = carousel.getAttribute('data-title-bold');
            render(createElement(ProductsCarousel, {
                params: id,
                title,
                titleBold,
                api: getProductClassificationRelatedGenus,
                colClass: 'col-lg-3 col-xs-6',
                type: 'genus'
            }), carousel);
        }
    }

    startRPCarousels() {
        for (let i = 0; i < this.rpCarousels.length; i++) {
            const carousel = this.rpCarousels[i],
                id = carousel.getAttribute('data-id'),
                title = carousel.getAttribute('data-title'),
                titleBold = carousel.getAttribute('data-title-bold');
            render(createElement(ProductsCarousel, {
                params: id,
                title,
                titleBold,
                api: getProductClassificationSiblings,
                colClass: 'col-lg-3 col-sm-6',
                type: 'genus'
            }), carousel);
        }
    }

    startProductsCarousels() {
        for (let i = 0; i < this.productsCarousels.length; i++) {
            const carousel = this.productsCarousels[i],
                type = carousel.getAttribute('data-type'),
                sectionClass = carousel.getAttribute('data-section-class'),
                sectionTitleClass = carousel.getAttribute('data-section-title-class'),
                items = JSON.parse(carousel.getAttribute('data-items')),
                title = carousel.getAttribute('data-title'),
                titleBold = carousel.getAttribute('data-title-bold');
            render(createElement(ProductsCarousel, {
                type,
                items,
                title,
                titleBold,
                sectionClass,
                sectionTitleClass,
                colClass: 'col-lg-3 col-xs-6',
                type: 'genus'
            }), carousel);
        }
    }

    startVideoCarousels() {
        for (let i = 0; i < this.videoCarousels.length; i++) {
            const carousel = this.videoCarousels[i],
                sectionClass = carousel.getAttribute('data-section-class'),
                title = carousel.getAttribute('data-title'),
                sectionTitleClass = carousel.getAttribute('data-section-title-class'),
                titleBold = carousel.getAttribute('data-title-bold'),
                items = JSON.parse(carousel.getAttribute('data-videos'));
            render(createElement(VideosCarousel, { items, title, titleBold, colClass: 'col-lg-4 col-sm-6', sectionClass, sectionTitleClass }), carousel);
        }
    }

    startBlogCarousels() {
        for (let i = 0; i < this.blogCarousels.length; i++) {
            const carousel = this.blogCarousels[i],
                sectionClass = carousel.getAttribute('data-section-class'),
                title = carousel.getAttribute('data-title'),
                sectionTitleClass = carousel.getAttribute('data-section-title-class'),
                titleBold = carousel.getAttribute('data-title-bold'),
                items = JSON.parse(carousel.getAttribute('data-blogs'));
            render(createElement(BlogsCarousel, { items, title, titleBold, sectionClass, sectionTitleClass }), carousel);
        }
    }
}