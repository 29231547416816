import React, { Component } from "react";
import CarouselSection from './carousel-section.jsx';
import VideoItem from './video-item.jsx';
import Lazyload from './lazyload.jsx';
import Magnific from './magnific.jsx';
import CSSTransition from 'react-transition-group/CSSTransition';

class SingleVideo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imageVisible: false,
            showModal: false
        };

        this.openGallery = this.openGallery.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleLazyloadVisible = this.handleLazyloadVisible.bind(this);
    }

    get galleryItems() {
            let items = [{
                type: this.props.items.url.indexOf('.mp4') >= 0 ? 'video' : 'iframe',
                src: this.props.items.url,
                description: this.props.items.title
                }]
        return items;
    }

    openGallery(e) {
        e.preventDefault();
        let link = e.target;
        while (link.tagName !== 'A') {
            link = link.parentElement;
        }

        this.setState({
            showModal: true
        });
    }

    handleClose() {
        this.setState({
            showModal: false
        });
    }

    handleLazyloadVisible() {
        this.setState({
            imageVisible: true
        });
    }

    render() {
        if (this.props.items) {
            return (
                    <div>
                        <section className="card">
                            <div className="card-media video tall">
                                <CSSTransition in={this.state.imageVisible} timeout={250} classNames="fade">
                                    <Lazyload height="100%" rootMargin="100px" onVisible={this.handleLazyloadVisible}>
                                        <a href={this.props.items.Url} onClick={this.openGallery} target="_blank" >
                                            <img  srcSet={`${this.props.items.thumbnail}?width=440&resizemode=force 1x, ${this.props.items.thumbnail}?width=880&resizemode=force 2x`} alt={this.props.items.title} />
                                            <svg className="icon icon-play card-media-play"><use xlinkHref="#icon-play"></use></svg>
                                        </a>
                                    </Lazyload>
                                </CSSTransition>
                            </div>
                        <h3 className="card-title">{this.props.items.title}</h3>
                        <Magnific items={this.galleryItems} show={this.state.showModal} onClose={this.handleClose} />
                        </section>                       
                    </div>                 
            );
        }

        return null;
    }
}

export default SingleVideo;