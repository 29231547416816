import jfetch from '@jontetz/jfetch';

export function postServingSystemsForm(data) {
    return jfetch.post(`/api/servingsystemform/form`, data);
}

export function postProductRegistration(data) {
    return jfetch.post(`/api/productregistration/form`, data);
}

export function postInventorsSubmissionForm(formData) {
    return fetch('/api/inventorssubmissionform/form', {
            body: formData,
            method: "post",
        })
        .then(response => {
            if (!response.ok) throw response;
            return response.json();
        });
}

export function postPotentialDomesticCarrierRegistrationForm(data) {
    return jfetch.post(`/api/PotentialDomesticCarrierRegistrationForm/form`, data);
}

export function postPotentialSupplierRegistrationForm(data) {
    return jfetch.post(`/api/PotentialSupplierRegistrationForm/form`, data);
}

export function postVUEventCodeForm(data) {
    return jfetch.post(`/api/Events/vueventcodeform`, data);
}

export function postVUEventRegistrationForm(data) {
    return jfetch.post(`/api/Events/vueventregistrationform`, data);
}
export function postVUEventFlightConfirmationForm(data) {
    return jfetch.post(`/api/Events/vueventflightconfirmationform`, data);
}

export function postVUEventDriveConfirmationForm(data) {
    return jfetch.post(`/api/Events/vueventdriveconfirmationform`, data);
}

export function getEventConfirmedInfo(data) {
    return jfetch.get(`/api/Events/getvueventconfirmedinfo`, data);
}

export function postEventConfirmedInfo(data) {
    return jfetch.post(`/api/Events/postvueventconfirmedinfo`, data);
}

export function postVirtualRegistration(data) {
    return jfetch.post(`/api/Events/virtualvuregistrationform`, data);
}

export function logoutUser() {
    return jfetch.post(`/Account/signout`);
}

export function newsletterSubscription(data) {
    return jfetch.post(`/api/newsletter/subscribe`, data);
}

export function qualityControlSubmission(data) {
    return jfetch.post(`/api/qualitycontrol/postqcsubmission`, data);
}