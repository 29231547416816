import RelatedBlogsSection from '../react/components/related-blogs-section.jsx';
import RecipesSection from '../react/components/recipes-section.jsx';
import AllArticles from '../react/components/get-all-articles.jsx';
import { createElement } from 'react';
import { render } from 'react-dom';

export default class Blog {
    constructor() {
        this.relatedBlogs = document.querySelectorAll('.js-related-blog-posts');
        this.recipes = document.querySelectorAll('.js-recent-recipes');
        this.articles = document.querySelectorAll('.js-all-articles')

        if (this.relatedBlogs.length) {
            this.startRelatedBlogs();
        }
        if (this.recipes.length) {
            this.startRecipes();
        }
        if (this.articles.length) {
            this.startAllArticles();
        }
    }

    startRelatedBlogs() {
        for (let i = 0; i < this.relatedBlogs.length; i++) {
            const section = this.relatedBlogs[i],
                currentId = section.getAttribute('data-current-id'),
                tags = JSON.parse(section.getAttribute('data-tags')),
                title = section.getAttribute('data-title'),
                titleBold = section.getAttribute('data-title-bold');
            render(createElement(RelatedBlogsSection, { currentId, title, titleBold, tags }), section);
        }
    }

    startRecipes() {
        for (let i = 0; i < this.recipes.length; i++) {
            const section = this.recipes[i],
                currentId = section.getAttribute('data-current-id'),
                title = section.getAttribute('data-title'),
                titleBold = section.getAttribute('data-title-bold'),
                link = section.getAttribute('data-link'),
                linkText = section.getAttribute('data-link-text');
            render(createElement(RecipesSection, { currentId, title, titleBold, link, linkText }), section);
        }
    }

    startAllArticles() {
        for (let i = 0; i < this.articles.length; i++) {
            const section = this.articles[i],
                currentId = section.getAttribute('data-current-id'),
                title = section.getAttribute('data-title'),
                titleBold = section.getAttribute('data-title-bold'),
                link = section.getAttribute('data-link'),
                linkText = section.getAttribute('data-link-text');
            render(createElement(AllArticles, { currentId, title, titleBold, link, linkText }), section);
        }
    }
}