import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SearchMenuBase from './search-menu-base.jsx';



class SearchMenuContainer extends SearchMenuBase {
    constructor(props) {
        super(props);

        this.handleKeyup = this.handleKeyup.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.isOpen !== this.state.isOpen) {
            if (this.state.isOpen) {
                setTimeout(() => {
                    this.searchInput.current.focus();
                    document.addEventListener('keyup', this.handleKeyup);
                }, 400);
            }
            else {
                document.removeEventListener('keyup', this.handleKeyup);
            }
        }
    }

    handleKeyup(e) {
        switch (e.keyCode) {
            // esc key
            case 27:
                this.setState({
                    isOpen: false
                });
                break;
        }
    }

    renderPortal() {
        const drawer = (
            <div key="drawer" className={`search-drawer ${this.state.isOpen ? 'open' : ''}`}>
                <form action="/search" method="get" onSubmit={this.handleFormSubmit}>
                    <div className="search-drawer-input-container">
                        <label className="sr-only" htmlFor="search-field">Search</label>
                        <input ref={this.searchInput} className="search-drawer-input" id="search-field" type="text" name="q" placeholder="Search..." onChange={this.handleChange} required autoCapitalize="none" autoCorrect="off" />
                        <button className="search-drawer-btn">
                            <svg className="icon" width="40" height="40"><use xlinkHref="#icon-search"></use></svg>
                            <span className="sr-only">Search</span>
                        </button>
                    </div>
                </form>
            </div>
        );

        return ReactDOM.createPortal(drawer, document.body);
    }

    render() {
        return [
            <button key="searchBtn" className="search-drawer-trigger" type="button" onClick={this.toggleSearch}>
                <svg className="icon" width="35" height="35"><use xlinkHref="#icon-search"></use></svg>
                <span className="search-drawer-label">Search...</span>
                <span className="sr-only">search icon</span>
            </button>,
            this.renderPortal()
        ];
    }
}

export default SearchMenuContainer;