import React, { useState, useEffect } from 'react';

const ProgressBar = props => {
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		setProgress(props.progress);
	}, [props.progress]);


	
	return (
		<>
			<div className="progress-bar-container">
				<div className="progress-bar"></div>
				<div className="progress-bar progress-tracker" style={`width:${progress ?? 0}%`}></div>
				<div className={`end-cap ${progress > 0 ? 'selected' : ''}`} ></div>
				<div className={`end-cap progress-end ${progress >= 100 ? 'selected' : ''}`}></div>
				<div className="progress-text">{progress ?? 0}% Complete</div>
			</div>
		</>
	);
}

export default ProgressBar;