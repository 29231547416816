import { createElement } from 'react';
import { render } from 'react-dom';

export default class CarouselWidgetContainer {
    constructor() {
        this.containers = document.querySelectorAll('.js-carousel-widget');

        if (this.containers.length) {
            this.containers.forEach((item) => {
                this.start(item);
            })
        }
    }

    start(item) {
        const title = item.getAttribute('data-title'),
            titleBold = item.getAttribute('data-title-bold'),
            tallCards = item.getAttribute('data-tall-cards').toLowerCase() === 'true',
            items = JSON.parse(item.getAttribute('data-items') || "[]");
        let cardWidth;
        switch (item.getAttribute('data-card-width')) {
            case 'very small':
                cardWidth = 'col-md-2';
                break;
            case 'small':
                cardWidth = 'col-lg-3 col-md-6';
                break;
            case 'big':
                cardWidth = 'col-lg-6';
                break;
            case 'full width':
                cardWidth = 'col-12';
                break;
        }
        import('../react/components/carousel-widget.jsx')
            .then(CarouselWidget => {
                render(createElement(CarouselWidget.default, { title, titleBold, cardWidth, items, tallCards }), item);
            });
    }
}