export default class Print {
    constructor() {
        this.buttons = document.querySelectorAll('.js-print');

        if (this.buttons.length) {
            this.addListeners();
        }
    }

    addListeners() {
        this.clickBind = this.click.bind(this);
        for (let i = 0; i < this.buttons.length; i++) {
            this.buttons[i].addEventListener('click', this.clickBind);
        }
    }

    click(e) {
        e.preventDefault();
        window.print();
    }

    destroy() {
        if (this.buttons.length) {
            for (let i = 0; i < this.buttons.length; i++) {
                this.buttons[i].removeEventListener('click', this.clickBind);
            }
        }
    }
}