
class LazyImage {
    constructor(img) {
        this.hasLoaded = false;
        this.img = img;
        this.picture = img.parentNode.tagName.toLowerCase() === 'picture' ? img.parentNode : null;

        this.loaded = this.loaded.bind(this);
        this.error = this.error.bind(this);
    }

    load() {
        const promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });

        this.img.addEventListener('load', this.loaded);
        this.img.addEventListener('error', this.error);

        this.setSource();
        if (this.img.complete) this.loaded();

        return promise;
    }

    loaded() {
        this.img.removeEventListener('load', this.loaded);
        this.img.removeEventListener('error', this.error);
        this.hasLoaded = true;
        this.img.classList.add('loaded');
        this.finish();
    }

    error() {
        this.img.classList.add('error');
        this.reject('Image load error');
    }

    setSource() {
        if (this.picture) {
            const sources = [...this.picture.querySelectorAll('source')];
            sources.forEach(source => {
                source.setAttribute('srcset', source.getAttribute('data-srcset'));
            });
        }
        if (this.img.getAttribute('data-srcset')) this.img.setAttribute('srcset', this.img.getAttribute('data-srcset'));
        if (this.img.getAttribute('data-src')) this.img.setAttribute('src', this.img.getAttribute('data-src'));
    }

    finish() {
        this.resolve();
    }
}

class LazyLoader {
    constructor(options = {}) {
        this.observerOptions = Object.assign({
            rootMargin: '0px',
            threshold: 0
        }, options.observer);
        this.settings = Object.assign({
            selector: '.lazy'
        }, options);

        this.checkIntersection = this.checkIntersection.bind(this);
        this.observer = new IntersectionObserver(this.checkIntersection, this.observerOptions);
        this.find();
    }

    find() {
        const images = [...document.querySelectorAll(this.settings.selector)];
        this.images = [];
        for (let i = 0; i < images.length; i++) {
            this.images.push(new LazyImage(images[i]));
            this.observer.observe(images[i]);
        }
    }

    checkIntersection(entries, observer) {
        entries.forEach(entry => {
            // console.log(entry);
            if (entry.isIntersecting) {
                observer.unobserve(entry.target);
                this.load(entry.target);
            }
        });
    }

    load(target) {
        const lazyImage = this.images.find(i => i.img === target);
        lazyImage.load();
    }
}

export default LazyLoader;