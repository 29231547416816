export default class SocialShare {
    constructor() {
        this.share = document.querySelector('.js-social-share');

        if (this.share) {
            this.toggles = document.querySelectorAll('.js-social-share-toggle');
            this.toggle = this.toggle.bind(this);
            this.start();
        }
    }

    start() {
        [...this.toggles].forEach(toggle => {
            toggle.addEventListener('click', this.toggle);
        });
    }

    toggle() {
        this.share.classList.toggle('expanded');
        const isExpanded = this.share.classList.contains('expanded');

        if (isExpanded) [...this.toggles].forEach(t => t.setAttribute('data-expanded', 'true'));
        else [...this.toggles].forEach(t => t.setAttribute('data-expanded', 'false'));
    }
}