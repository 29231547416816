import React, { Component } from "react";
import CarouselSectionSwitcher from './carousel-section-switcher.jsx';
import Blog from './blog-item.jsx';
import { getArticles } from '../../apis/inspiration';

class AllArticles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            isLoading: true
        };
    }

    componentDidMount() {
        getArticles({
            page: 1,
            pageSize: 3,
        })
            .then(data => {
                this.setState({
                    items: data.Records,
                    isLoading: false
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    isLoading: false
                });
            });
    }

    render() {
        if (this.state.items.length || this.state.isLoading) {
            const carouselItems = this.state.items.map((blog, index) => (
                <Blog key={index} {...blog} />
            ));
            const sectionItems = <div className="row">
                {this.state.items.map((blog, index) => (
                    <Blog key={index} {...blog} />
                ))}
            </div>;

            return (
                <CarouselSectionSwitcher switchWidth={575} title={this.props.title} titleBold={this.props.titleBold} link={this.props.link} linkText={this.props.linkText} carousel={carouselItems} section={sectionItems} />
            );
        }

        return null;
    }
}

export default AllArticles;