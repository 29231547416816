import React, { useEffect, useState } from 'react';
import LoaderSection from "../loader.jsx";

const NavigationButton = props => {

	const [button, setButton] = useState(props.button);
	const [buttonText, setButtonText] = useState(props.button.text);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);

	useEffect(() => {
		if ((!props.isValid && (button.actionTypeId == 1 || button.actionTypeId == 5)) || isSubmitting) {
			setIsDisabled(true)
		} else {
			setIsDisabled(false);
		}
	}, [props.isValid, isSubmitting]);

	useEffect(() => {
		if (!props.isValid) {
			setIsSubmitting(false);
		}
	}, [props.isValid]);

	useEffect(() => {
		setButtonText(isSubmitting ? "Submitting..." : props.button.text)
	}, [isSubmitting]);

	const handleClick = (e) => {
		if (!props.isValid) {
			return;
		}


		if (props.button.actionTypeId != 3) {
			e.preventDefault();
		}

		switch (props.button.actionTypeId) {
			case 1: //nav forward
				props.onNext();
				break;
			case 2: //nav backward
				props.onPrevious();
				break;
			case 3: //link
				return true;
				break;
			case 4: //jump to step
				props.onJump(button.jumpStepId);
				break;
			case 5: //submit
				setIsSubmitting(true);
				props.onSubmit(button.submitTypeId, () => {
					setIsSubmitting(false);
				});
				break;
		}
		
	};
	
	return (
		<div className={`navigation-button ${button.isTwoColumn ? 'col-lg-6' : 'col-xl-6 offset-xl-3 col-12'} ${button.alignmentId == 1 ? 'text-left' : ''} ${button.alignmentId == 2 ? 'text-right' : ''}`}>
			<a className={`btn ${button.usePrimaryTheme ? 'btn-primary' : 'btn-outline'} ${button.actionTypeId == 1 ? 'btn-next' : ''} ${button.actionTypeId == 2 ? 'btn-prev' : ''} ${!button.isTwoColumn ? 'full' : ''} ${isDisabled ? 'disabled' : ''} ${props.isDisabled ? 'disabled' : ''}`} href={button.linkUrl} onClick={handleClick}>{buttonText}<LoaderSection show={isSubmitting} /></a>
		</div>
	);
}

export default NavigationButton;