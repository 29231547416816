import { createElement } from 'react';
import { render } from 'react-dom';
import Magnific from '../react/components/magnific.jsx';

export default class VideoModal {
    constructor() {
        this.buttons = document.querySelectorAll('.js-video-modal');

        if (this.buttons.length) {
            this.magnificRoot = document.createElement('div');
            document.body.appendChild(this.magnificRoot);
            this.addListeners();
        }
    }

    addListeners() {
        this.clickBind = this.click.bind(this);
        for (let i = 0; i < this.buttons.length; i++) {
            this.buttons[i].addEventListener('click', this.clickBind);
        }
    }

    click(e) {
        e.preventDefault();
        let target = e.target;
        while (target.tagName !== 'A') {
            target = target.parentElement;
        }
        this.modalProps = {
            items: [
                {
                    type: target.href.indexOf('mp4') >= 0 ? 'video' : 'iframe',
                    src: target.href,
                    description: target.dataset.title
                }
            ],
            show: true
        };
        this.renderMagnific();
    }

    renderMagnific() {
        render(createElement(Magnific, this.modalProps), this.magnificRoot);
    }

    destroy() {
        if (this.buttons.length) {
            for (let i = 0; i < this.buttons.length; i++) {
                this.buttons[i].removeEventListener('click', this.clickBind);
            }
        }
    }
}