import throttle from 'lodash/throttle';

export default class VMSMenu {
    constructor() {
        this.body = document.querySelector('body');
        this.menuLinks = document.querySelectorAll('.js-menu-link-vms');
        this.isTouch = "ontouchstart" in document.documentElement;

        this.scrollChangeDistance = 200;

        if (this.menuLinks.length) {

            this.askQuestionTrigger = document.querySelectorAll('.js-ask-question-trigger');
            this.askQuestionSection = document.querySelector('.js-chat-section-nav');
            this.askQuestionContainer = document.querySelector('.header-badges-wrapper');
            this.askQuestionCloseBtn = document.querySelector('.js-chat-section-close');
            this.menuToggle = document.querySelector('.js-menu-trigger');
            this.tertiaryNav = document.querySelector('.company-container');
            this.menuTertiaryNav = document.querySelector('.js-mobile-tertiary-container');
            this.secondaryNav = document.querySelector('.js-secondary-nav');
            this.menuSecondaryNav = document.querySelector('.js-mobile-secondary-container');
            this.dropdownTriggers = document.querySelectorAll('.js-menu-dropdown-trigger');
            this.megaDropdownTriggers = document.querySelectorAll('.js-mega-menu-dropdown-trigger');

            this.bodyClick = this.bodyClick.bind(this);
            this.toggleMenu = this.toggleMenu.bind(this);

            this.addListeners();

            this.menuTertiaryNav.appendChild(this.tertiaryNav.cloneNode(true));
            this.menuSecondaryNav.appendChild(this.secondaryNav.cloneNode(true));

            if (window.headerScrollElement) {
                this.scrollElement = document.querySelector(window.headerScrollElement);
            }
        }
    }

    addListeners() {
        this.scrollChangeBinded = throttle(this.scrollChange.bind(this), 50);
        document.addEventListener("scroll", this.scrollChangeBinded);

        for (let i = 0; i < this.dropdownTriggers.length; i++) {
            const link = this.dropdownTriggers[i];
            link.addEventListener('click', e => {
                this.toggleDropdown(link);
            });
        }

        for (let i = 0; i < this.menuLinks.length; i++) {
            const link = this.menuLinks[i];
            link.parentElement.addEventListener('mouseenter', e => {
                this.closeAskQuestion();
                [...link.parentElement.querySelectorAll('img')].forEach(img => {
                    img.srcset = img.dataset.srcset;
                });
            });
        }

        for (let i = 0; i < this.askQuestionTrigger.length; i++) {
            const trigger = this.askQuestionTrigger[i];
            trigger.addEventListener('click', e => {
                e.stopPropagation();
                this.toggleAskQuestion(trigger);
            });
        }

        for (let i = 0; i < this.megaDropdownTriggers.length; i++) {
            const link = this.megaDropdownTriggers[i];
            link.addEventListener('click', e => {
                this.toggleMegaDropdown(link);
            });
        }

        this.askQuestionCloseBtn.addEventListener('click', e => {
            this.toggleAskQuestion();
        });

        this.askQuestionSection.addEventListener('click', e => {
            e.stopPropagation();
        });

        this.menuToggle.addEventListener('click', this.toggleMenu);
    }

    toggleDropdown(link) {
        link.parentElement.classList.toggle('show-dropdown');
        const isOpen = link.parentElement.classList.contains('show-dropdown');

        if (isOpen) link.setAttribute('data-expanded', 'true');
        else link.setAttribute('data-expanded', 'false');

        [...link.parentElement.querySelectorAll('img')].forEach(img => {
            img.srcset = img.dataset.srcset;
        });
    }

    toggleMegaDropdown(link) {
        link.parentElement.parentElement.classList.toggle('show-mega');
        const isOpen = link.parentElement.parentElement.classList.contains('show-mega');

        if (isOpen) link.setAttribute('data-expanded', 'true');
        else link.setAttribute('data-expanded', 'false');
    }

    toggleAskQuestion(trigger) {
        this.askQuestionContainer.classList.toggle('show-ask-question');
        this.askQuestionSection.classList.toggle('show');
        this.body.classList.remove('show-menu');

        if (this.askQuestionSection.classList.contains('show')) {
            if (trigger) trigger.setAttribute('aria-expanded', 'true');
            this.body.addEventListener('click', this.bodyClick);
        }
        else {
            for (let i = 0; i < this.askQuestionTrigger.length; i++) {
                this.askQuestionTrigger[i].setAttribute('aria-expanded', 'false');
            }
            this.body.removeEventListener('click', this.bodyClick);
        }
    }

    closeAskQuestion() {

        this.askQuestionSection.classList.remove('show');
        for (let i = 0; i < this.askQuestionTrigger.length; i++) {
            this.askQuestionTrigger[i].setAttribute('aria-expanded', 'false');
        }

        this.body.removeEventListener('click', this.bodyClick);
    }

    toggleMenu() {
        this.body.classList.toggle('show-menu');
        let banner = document.getElementById('info-banner');
        if (banner) {
            banner.classList.toggle('show-banner');
        }

        if (this.body.classList.contains('show-menu')) {
            this.menuToggle.setAttribute('data-expanded', 'true');
        }
        else {
            this.menuToggle.setAttribute('data-expanded', 'false');
        }
    }

    bodyClick(e) {
        if (this.askQuestionSection.classList.contains('show')) this.toggleAskQuestion();
        this.closeDropdowns();
    }

    scrollChange(e) {
        const scrollHeight = window.pageYOffset;
        let distance = this.scrollChangeDistance;
        if (this.scrollElement) {
            distance = this.scrollElement.offsetTop + this.scrollElement.offsetHeight;
        }
        if (scrollHeight >= distance) {
            this.body.classList.add('header-sticky');
        } else if (scrollHeight < distance) {
            this.body.classList.remove('header-sticky');
        }
    }
}
