import jfetch from '@jontetz/jfetch';

export function getCollection(id) {
    return jfetch.get(`/api/productcollection/getcollection`, {
        collectionId: id
    });
}

export function createCollection() {
    return jfetch.post('/api/productcollection/createcollection');
}

export function addProductToCollection(collectionId, itemNumber) {
    return jfetch.post(`/api/productcollection/addproduct?collectionId=${collectionId}&itemNumber=${itemNumber}`);
}

export function deleteProductFromCollection(collectionId, itemNumber) {
    return jfetch.post(`/api/productcollection/deleteproduct?collectionId=${collectionId}&itemNumber=${itemNumber}`);
}