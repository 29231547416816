import recentlyViewedProducts from '../helpers/recently-viewed-products';
import Flickity from 'flickity';
import { createElement } from 'react';
import { render } from 'react-dom';
import VideosCarousel from '../react/components/videos-carousel.jsx';
// import AskAQuestion from '../react/components/ask-a-question.jsx';
import ProductWishlistBtn from '../react/components/product-wishlist-btn.jsx';
import toast from './toast';

export default class Product {
    constructor() {
        this.product = document.querySelector('.js-product');

        if (this.product) {
            this.id = this.product.getAttribute('data-id');
            this.productImages = this.product.querySelector('.js-product-images');
            this.productVideos = this.product.querySelector('.js-product-videos');
            this.askAQuestion = this.product.querySelector('.js-ask-a-question');
            this.addToWishlist = [...this.product.querySelectorAll('.js-add-to-wishlist')];
            this.addToProjectBuilder = [...this.product.querySelectorAll('.js-add-to-project-builder')];
            this.drawingRoot = this.product.querySelector('.js-drawing-root');
            this.start();
        }
    }

    start() {
        recentlyViewedProducts.add(this.id);
        if (this.productImages.querySelectorAll('.js-product-image').length > 1) {
            new Flickity(this.productImages, {
                // options...
                pageDots: true,
                cellAlign: 'center',
                contain: true,
                lazyLoad: 1,
                adaptiveHeight: true,
                imagesLoaded: true
            });
        }

        if (this.productVideos) this.renderVideos();
        this.renderAskAQuestion();

        if (window.location.hash === '#searchmatch=true') {
            toast.success('We found a direct product match for your search');
            window.location.hash = '';
        }
        this.renderWishlistBtn();

        if (this.addToProjectBuilder) this.renderQuickAddBtn();
        this.renderDrawingModal();
    }

    renderWishlistBtn() {
        if (this.addToWishlist.length) {
            this.addToWishlist.forEach(item => {
                const id = item.getAttribute('data-item'),
                    btnClass = item.dataset.class;
                render(createElement(ProductWishlistBtn, { id, btnClass }), item);
            });
        }
    }

    //used to add products to the project builder
    renderQuickAddBtn() {
        if (this.addToProjectBuilder.length && (window.location.host == ("localhost:44335") || window.location.host == ("localhost:7012") || window.location.hostname.includes("vollrathfoodservice.com"))) {
            import('../../../js/site-js/react/components/project-builder/quick-add-query-client.jsx').then((QuickAddBtn) => {
                this.addToProjectBuilder.forEach(product => {
                    const item = JSON.parse(product.getAttribute('data-item'));
                    render(createElement(QuickAddBtn.default, { item }), product);
                });
            });

        }
    }

    renderVideos() {
        if (this.productVideos.getAttribute('data-items')) {
            const title = this.productVideos.getAttribute('data-title'),
                titleBold = this.productVideos.getAttribute('data-title-bold'),
                defaultImg = this.productVideos.getAttribute('data-default-img'),
                items = JSON.parse(this.productVideos.getAttribute('data-items')).map(v => {
                    let image = v.thumbnail !== null ? v.thumbnail : defaultImg
                    return {                       
                        image: image,
                        url: v.link,
                        title: v.displayName,
                        type: v.resourceExtension === 'mp4' ? 'video' : 'iframe'
                    };
                });
            render(createElement(VideosCarousel, { title, titleBold, items }), this.productVideos);
        }
    }

    renderAskAQuestion() {
        const id = this.askAQuestion.dataset.id,
              redirectTo = this.askAQuestion.dataset.redirectTo;
        import('../react/components/forms/ask-a-question.jsx')
            .then(AskAQuestion => {
                render(createElement(AskAQuestion.default, { id, redirectTo }), this.askAQuestion);
            });
    }

    renderDrawingModal() {
        if (this.drawingRoot) {
            import('../react/components/product-drawing-modal.jsx')
            .then(ProductDrawingModal => {
                render(createElement(ProductDrawingModal.default), this.drawingRoot);
            });
        }        
    }
}