import { createElement } from 'react';
import { render } from 'react-dom';

export default class SearchContainer {
    constructor() {
        this.container = document.querySelector('.js-search-new-root');

        if (this.container) {
            this.start();
        }
    }

    start() {      
        import('../react/pages/search.jsx')
            .then(Search => {
                render(createElement(Search.default), this.container);
            });
    }
}