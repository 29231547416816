import React from 'react';
import ReactDOM from 'react-dom';
import App from '../react/components/ab-1200/AB1200.jsx';


export default class AB1200 {
    constructor() {
        this.container = document.querySelector('#ab-1200-js-app');

        if (this.container) {
            ReactDOM.render(
                <React.StrictMode>
                    <App codes={this.container.dataset.codes} />
                </React.StrictMode >,
                document.getElementById('ab-1200-js-app')
            );
        }
    }
}