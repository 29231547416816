import { createCollection, addProductToCollection, deleteProductFromCollection, getCollection } from '../apis/product-collection';

class Wishlist {
    constructor() {
        this.id = localStorage['wishlist-guid'];
        if (this.id) this.get();
    }

    async get() {
        if(!this.getPromise) this.getPromise = getCollection(this.id);
        this.data = await this.getPromise;
        // unset promise for next fetch
        this.getPromise = undefined;
        this.updateNumbers();
        if (!this.data) {
            localStorage.removeItem('wishlist-guid');
            this.id = undefined;
        }
        return this.data;
    }

    async create() {
        const data = await createCollection();
        this.id = data;
        localStorage['wishlist-guid'] = this.id;
    }

    async add(itemNumber) {
        // create wishlist first no id
        if (!this.id) await this.create();
        const data = await addProductToCollection(this.id, itemNumber);
        // update wishlist data
        this.get();
        return data;
    }

    async delete(itemNumber) {
        await deleteProductFromCollection(this.id, itemNumber);
        // update wishlist data
        this.get();
        return true;
    }

    async hasProduct(itemNumber) {
        if (!this.id) return false;
        if (!this.data) await this.get();
        const product = this.data.products.find(p => p.itemNumber === itemNumber);
        if (product) return true;
        return false;
    }

    updateNumbers() {
        if (this.data && this.data.products) {
            const numbers = [...document.querySelectorAll('.js-wishlist-number')];
            numbers.forEach(item => {
                item.innerHTML = this.data.products.length;
            });
        }
    }
}

export default new Wishlist();