// import FamilyGenusContainer from '../react/components/family-genus-grid.jsx';
import { createElement } from 'react';
import { render } from 'react-dom';

export default class FamilyGenus {
    constructor() {
        this.container = document.querySelector('.js-family-genus');

        if (this.container) {
            this.start();
        }
    }

    start() {
        const id = this.container.getAttribute('data-id'),
            isVirtual = !!this.container.getAttribute('data-is-virtual'),
            virtualPassthrough = this.container.getAttribute('data-virtual-passthrough');

        import('../react/components/family-genus-grid.jsx')
            .then(FamilyGenusContainer => {
                render(createElement(FamilyGenusContainer.default, { id, isVirtual, virtualPassthrough }), this.container);
            });
    }
}