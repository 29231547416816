import jfetch from '@jontetz/jfetch';

export function getAllStepsByVersionId(versionId) {
    return jfetch.get(`/api/customerfittool/getallstepsbyversionid?versionId=${versionId}`);
}

export function getVersionById(id) {
    return jfetch.get(`/api/customerfittool/getversionbyid?id=${id}`);
}

export function saveUserData(data) {
    return jfetch.post('/api/customerfittool/saveuserdata', data);
}

export function uploadFiles(userId, files) {
    return fetch(`/api/customerfittool/uploadfiles?userId=${userId}`, {
        method: 'POST',
        body: files,
    }).then((response) => { return response.json(); });
}

export function submitGoodFitForm(params) {
    return jfetch.post('/api/customerfittool/submitgoodfitform', params);
}

export function submitSemiFitForm(params) {
    return jfetch.post('/api/customerfittool/submitsemifitform', params);
}

export function submitNonFitForm(params) {
    return jfetch.post('/api/customerfittool/submitnonfitform', params);
}

export function submitEmailForm(params) {
    return jfetch.post('/api/customerfittool/submitemailform', params);
}

export function deleteUserData(id) {
    return jfetch.post(`/api/customerfittool/deleteuserdata?userid=${id}`);
}