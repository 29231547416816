import { createElement } from 'react';
import { render } from 'react-dom';

export default class Dashboard {
    constructor() {
        this.container = document.querySelector('.js-dashboard');

        if (this.container) {
            this.start();
        }
    }

    start() {
        const email = this.container.getAttribute('data-email');
        import('../react/pages/dashboard.jsx')
            .then(Dashboard => {
                render(createElement(Dashboard.default, { email }), this.container);
            });
    }
}