import React, { useState, useEffect } from 'react';

const RadioButton = props => {
	const [htmlId, setHtmlId] = useState(`question-${props.question?.id}`);
	const [answers, setAnswers] = useState(props.question?.answers);
	const [selectedValue, setSelectedValue] = useState();
	const [cssClass, setCssClass] = useState(props.cssClass ?? '');

	useEffect(() => {
		setSelectedValue(props.selectedValue)
	}, [props.selectedValue]);

	const handleChange = (e) => {
		setSelectedValue(e.target.value);
		props.onChange(e.target.value);
	};

	return (
		<>
			{props.question &&
				<div className={`cft-radio-button ${cssClass} ${props.validationCssClass}`}>
					{props.validationMessage && props.validationCssClass &&
						<div className="row">
							<div className="col">
                                <span className="validation-message">{props.validationMessage}</span>
                            </div>
                        </div>
                    }
					<div className="row">
						{answers && answers.map((item, index) => {
							return (
								<>
									<div className={`radio-item col-lg-${Math.max(Math.ceil(12 / answers.length), 3)} col-6`}>
										<label>
											<input type="radio" className={`form-control ${props.validationCssClass}`} value={item.value} name={htmlId} checked={selectedValue === item.value} onBlur={handleChange} />
											<span className="btn-label">{item.value}</span>
										</label>
									</div>
								</>
							);
						})}
					</div>
				</div>
			}
		</>
	);
}

export default RadioButton;