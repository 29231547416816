// import WhereToBuy from '../react/pages/where-to-buy.jsx';
import { createElement } from 'react';
import { render } from 'react-dom';

export default class WhereToBuyContainer {
    constructor() {
        this.container = document.querySelector('.js-sales-root');

        if (this.container) {
            this.start();
        }
    }

    start() {
        const id = this.container.getAttribute('data-id');
        const errorMsg = this.container.getAttribute('data-error-msg');
        const errorBtn = this.container.getAttribute('data-error-btn'),
            useDist = this.container.getAttribute('data-dist'),
            local = this.container.getAttribute('data-local'),
            national = this.container.getAttribute('data-national'),
            intl = this.container.getAttribute('data-intl'),
            online = this.container.getAttribute('data-online'),
            servingSystemSales = this.container.getAttribute('data-serving-system-sales'),
            productSales = this.container.getAttribute('data-product-sales'),
            localText = this.container.getAttribute('data-local-text'),
            internationalText = this.container.getAttribute('data-international-text'),
            servingSystemSalesText = this.container.getAttribute('data-serving-system-sales-text'),
            productSalesText = this.container.getAttribute('data-product-sales-text'),
            localUrl = this.container.getAttribute('data-local-url'),
            internationalUrl = this.container.getAttribute('data-international-url'),
            servingSystemSalesUrl = this.container.getAttribute('data-serving-system-sales-url'),
            productSalesUrl = this.container.getAttribute('data-product-sales-url');

        import('../react/pages/where-to-buy.jsx')
            .then(WhereToBuy => {
                render(createElement(WhereToBuy.default, { 
                    id, 
                    errorMsg, 
                    errorBtn, 
                    useDist, 
                    local, 
                    national, 
                    intl, 
                    online,
                    servingSystemSales,
                    productSales,
                    localText, 
                    internationalText,
                    servingSystemSalesText,
                    productSalesText,
                    localUrl, 
                    internationalUrl,
                    servingSystemSalesUrl,
                    productSalesUrl}),
                    this.container);
            });
    }
}