import React, { Component } from "react";
import Lazyload from './lazyload.jsx';
import CSSTransition from 'react-transition-group/CSSTransition';
import { htmlDecode } from '../../utils/';

class Blog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imageVisible: false
        };

        this.handleLazyloadVisible = this.handleLazyloadVisible.bind(this);
    }

    handleLazyloadVisible() {
        this.setState({
            imageVisible: true
        });
    }

    render() {
        if (!this.props.title) {
            return <></>;
        }
        const imageUrl = this.props.heroImage.replace('~', '');
        let heading;

        if (this.props.cardSubHeading) {
            heading = this.props.cardSubHeading;
        } else {
            heading = this.props.category ? this.props.category.Name : this.props.type
        }
        return (
            <div className={this.props.colClass}>
                <article className="card">
                    <span className="card-type"> {heading}</span>
                    <div className="card-media scrim">
                        <CSSTransition in={this.state.imageVisible} timeout={250} classNames="fade">
                            <Lazyload height="100%" rootMargin="100px" onVisible={this.handleLazyloadVisible}>
                                <a href={this.props.pageUrl} tabIndex="-1">
                                    <img srcSet={`${this.props.thumbnail.replace('~', '')} 1x, ${this.props.thumbnailHighRes.replace('~', '')} 2x`} alt={this.props.heroImageAlt} />
                                </a>
                            </Lazyload>
                        </CSSTransition>
                    </div>
                    <h3 className="card-title"><a href={this.props.pageUrl}>{this.props.title}</a></h3>
                    <p className="card-desc content">{htmlDecode(this.props.shortDescription)}</p>
                    <a className="card-link" href={this.props.pageUrl}>Read More ></a>
                </article>
            </div>
        );
    }
}

Blog.defaultProps = {
    colClass: 'col-lg-4 col-sm-6'
};

export default Blog;