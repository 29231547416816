import React, { Component } from "react";
import CarouselSectionSwitcher from './carousel-section-switcher.jsx';
import Product from './product-item.jsx';
import { getProducts } from '../../apis/products';
import recentlyViewedProducts from '../../helpers/recently-viewed-products';
import CarouselSection from './carousel-section.jsx';

class RecentProductsSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            isLoading: true
        };
    }

    componentDidMount() {
        if (recentlyViewedProducts.products.length) {
            // only grab 4 recent items
            getProducts(recentlyViewedProducts.products.slice(0, 8))
                .then(data => {
                    // console.log(data);
                    this.setState({
                        items: data.records,
                        isLoading: false
                    });
                })
                .catch(error => {
                    this.setState({
                        isLoading: false
                    });
                    console.log(error);
                });
        }
        else {
            this.setState({
                isLoading: false
            });
        }
    }

    render() {
        if (this.state.items.length || this.state.isLoading) {
            const carouselItems = this.state.items.map((product, index) => (
                    <Product key={index} {...product} colClass="col-lg-3 col-sm-6" />
            ));

            if (this.state.items.length > 4) {
                return (
                    <CarouselSection className={this.props.sectionClass} title={this.props.title} titleBold={this.props.titleBold}>
                        {carouselItems}
                    </CarouselSection>
                );
            }

            const sectionItems = <div className="row">
                {this.state.items.map((product, index) => (
                    <Product key={index} {...product} colClass="col-lg-3 col-sm-6" />
                ))}
            </div>;

            return (
                <CarouselSectionSwitcher className={this.props.sectionClass} switchWidth={575} title={this.props.title} titleBold={this.props.titleBold} carousel={carouselItems} section={sectionItems} showLoader={this.state.isLoading} />
            );
        }

        return null;
    }
}

export default RecentProductsSection;