import RecentProductsSection from '../react/components/recent-products-section.jsx';
import { createElement } from 'react';
import { render } from 'react-dom';

export default class RecentProducts {
    constructor() {
        this.container = document.querySelector('.js-recent-products');

        if (this.container) {
            this.start();
        }
    }

    start() {
        const sectionClass = this.container.getAttribute('data-section-class'),
            title = this.container.getAttribute('data-title'),
            titleBold = this.container.getAttribute('data-title-bold');

        render(createElement(RecentProductsSection, { sectionClass, title, titleBold }), this.container);
    }
}