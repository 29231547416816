import React, { Component } from "react";
import CarouselSection from './carousel-section.jsx';
import Product from './product-item.jsx';
import Genus from './genus-item.jsx';

class ProductsCarousel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: props.items,
            isLoading: true
        };
    }

    componentDidMount() {
        if (this.props.api) {
            this.props.api(this.props.params)
                .then(data => {
                    this.setState({
                        items: data.records || data,
                        isLoading: false
                    });
                })
                .catch(error => {
                    this.setState({
                        isLoading: false
                    });
                    console.log(error);
                });
        }
        else {
            this.setState({
                isLoading: false
            });
        }
    }

    render() {
        if (this.state.items.length || this.state.isLoading) {
            let items;
            switch (this.props.type) {
                case 'genus':
                    items = this.state.items.map((product, index) => (
                        <Genus id={product.documentName} key={index} colClass={this.props.colClass} {...product} />
                    ));
                    break;
                default:
                    items = this.state.items.map((product, index) => (
                        <Product key={index} colClass={this.props.colClass} {...product} />
                    ));
            }

            return (
                <CarouselSection className={this.props.sectionClass} titleClassName={this.props.sectionTitleClass} title={this.props.title} titleBold={this.props.titleBold}>
                    {items}
                </CarouselSection>
            );
        }

        return null;
    }
}

ProductsCarousel.defaultProps = {
    colClass: 'col-lg-3 col-xs-6',
    type: 'product',
    items: []
};

export default ProductsCarousel;