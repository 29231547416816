import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SearchMenuBase from './search-menu-base.jsx';
import LoaderSection from './loader-section.jsx';

class SearchMenuContainer extends SearchMenuBase {
    constructor(props) {
        super(props);

        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.handleKeyup = this.handleKeyup.bind(this);
        this.handleCategoryClick = this.handleCategoryClick.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.isOpen !== this.state.isOpen) {
            if (this.state.isOpen) {
                setTimeout(() => {
                    this.searchInput.current.focus();
                    document.addEventListener('click', this.handleDocumentClick, false);
                    document.addEventListener('keyup', this.handleKeyup);
                }, 400);
            }
            else {
                document.removeEventListener('click', this.handleDocumentClick, false);
                document.removeEventListener('keyup', this.handleKeyup);
            }
        }
    }

    handleCategoryClick(e) {
        this.setState({
            category: e.target.dataset.name
        });
    }

    handleDocumentClick(e) {
        if (this.searchDrawer.current.contains(e.target)) {
            return;
        }
        
        this.setState({
            isOpen: false
        });
    }

    handleKeyup(e) {
        switch (e.keyCode) {
            // esc key
            case 27:
                this.setState({
                    isOpen: false
                });
                break;
        }
    }

    renderPortal() {
        let loader,
            noResults;

        if (this.state.isLoading) {
            loader = <LoaderSection className="short" show={true} />;
        }

        let quickLinks = this.state.quickLinks.map((item, index) =>
            <li key={index}><a href={item.pageUrl}>{item.pageName}</a></li>
        )

        if (!this.state.isLoading &&
            this.state.productItems.length === 0 &&
            this.state.keywordItems.length === 0 &&
            this.state.documentItems.length === 0 &&
            this.state.q) {
            noResults = <p className="search-drawer-no-results">No results found</p>;
        }

        const drawer = (
            <div key="drawer" ref={this.searchDrawer} className={`search-drawer ${this.state.isOpen ? 'open' : ''}`}>
                <form action="/search" method="get" onSubmit={this.handleFormSubmit}>
                    <div className="search-drawer-input-container">
                        <label className="sr-only" htmlFor="search-field">Search</label>
                        <input ref={this.searchInput} className="search-drawer-input" id="search-field" type="text" name="q" placeholder="Search..." onChange={this.handleChange} required autoCapitalize="none" autoCorrect="off"/>
                        <input type="hidden" name="category" value={this.state.category}/>
                        <button className="search-drawer-btn">
                            <svg className="icon" width="40" height="40"><use xlinkHref="#icon-search"></use></svg>
                            <span className="sr-only">Search</span>
                        </button>
                    </div>
                    <div className="search-drawer-content">
                        {loader}
                        {noResults}
                        {this.state.productItems.length > 0 &&
                            <section className="search-drawer-section">
                                <h4 className="search-drawer-title">Products</h4>
                                <div className="search-drawer-section-content">
                                    <ul className="search-drawer-links">
                                        {this.state.productItems.map((item, index) => (
                                            <li key={item.nodeId}><a href={item.pageUrl}>{item.skuNumber} - {item.documentName}</a></li>
                                        ))}
                                        {this.state.productItems.length === 5 &&
                                        <li><button data-name="products" className="search-drawer-all-btn" onClick={this.handleCategoryClick}>View All ></button></li>
                                        }
                                    </ul>
                                </div>
                            </section>
                        }
                        {this.state.keywordItems.length > 0 &&
                            <section className="search-drawer-section">
                                <h4 className="search-drawer-title">Categories</h4>
                                <div className="search-drawer-section-content">
                                    <ul className="search-drawer-links">
                                        {this.state.keywordItems.map((item, index) => (
                                            <li key={item.nodeId}><a href={item.pageUrl}>{item.documentName}</a></li>
                                        ))}
                                    </ul>
                                </div>
                            </section>
                        }
                        {this.state.documentItems.length > 0 &&
                            <section className="search-drawer-section">
                                <h4 className="search-drawer-title">Documents</h4>
                                <div className="search-drawer-section-content">
                                    <ul className="search-drawer-links">
                                        {this.state.documentItems.map((item, index) => (
                                            <li key={item.nodeId}><a href={`/downloads/${item.localSlug}`}>{item.displayName}</a></li>
                                        ))}
                                    </ul>
                                </div>
                            </section>
                        }
                        {!this.state.q &&
                            <section className="search-drawer-section">
                                <h4 className="search-drawer-title">Quick Links</h4>
                                <div className="search-drawer-section-content">
                                    <ul className="search-drawer-links">
                                        {quickLinks}
                                    </ul>
                                </div>
                            </section>
                        }
                    </div>
                </form>
            </div>
        );

        return ReactDOM.createPortal(drawer, document.body);
    }

    render() {
        return [
            <button key="searchBtn" className="search-drawer-trigger" type="button" onClick={this.toggleSearch}>
                <svg className="icon" width="35" height="35"><use xlinkHref="#icon-search"></use></svg>
                <span className="search-drawer-label">Search...</span>
                <span className="sr-only">search icon</span>
            </button>,
            this.renderPortal()
        ];
    }
}

export default SearchMenuContainer;