

export default class MultiSelect {
    constructor() {
        this.multis = document.querySelectorAll("select[multiple]");

        this.addListeners();
    }

    addListeners() {

        for (let i = 0; i < this.multis.length; i++) {
            this.parent = this.multis[i];

            if (this.parent.children.length < 200) {
                this.selectAllOption = document.createElement('option');
                this.selectAllOption.value = '';
                this.selectAllOption.innerHTML = 'Select All';
                this.parent.insertBefore(this.selectAllOption, this.parent.firstChild);
                this.selectAllOption.addEventListener('mousedown', e => {
                    this.selectAll(e);
                });
            }


            for (let j = 0; j < this.parent.children.length; j++) {

                this.option = this.parent.children[j];

                this.option.addEventListener('mousedown', e => {
                    this.toggleSelected(e);
                });
            }
        }
    }

    selectAll(e) {
        e.preventDefault();
        this.target = e.target;

        this.select = this.target.parentElement;

        for (let i = 0; i < this.select.children.length; i++) {
            this.select.children[i].toggleAttribute('selected');
            this.select.children[i].classList.toggle('multi-selected');
        }
    }

    toggleSelected(e) {
        e.preventDefault();
        this.target = e.target;

        this.target.toggleAttribute('selected');

        this.target.classList.toggle('multi-selected');

        const scrollTop = this.target.parentNode.scrollTop;
        setTimeout(() => this.target.parentNode.scrollTo(0, scrollTop), 0);
    }
}