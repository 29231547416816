import jfetch from '@jontetz/jfetch';

export function getProductsByKeyword(params) {
    return jfetch.get('/api/search/product', params);
}

export function getClassificationsByKeyword(params) {
    return jfetch.get('/api/search/classification', params);
}

export function getProductByName(params) {
    return jfetch.get('/api/search/productbyname', params);
}

export function getInspiration(params) {
    return jfetch.get('/api/search/inspiration', params);
}

export function getVideos(params) {
    return jfetch.get('/api/search/videos', params);
}

export function getDocuments(params) {
    return jfetch.get('/api/search/documents', params);
}

export function getProductDocuments(params) {
    return jfetch.get('/api/search/productdocuments', params);
}

export function getMatchingProduct(params) {
    return jfetch.get('/api/search/productmatch', params);
}

export function getDocumentsByCategory(params) {
    return jfetch.get('/api/search/documents', params);
}

export function getDocCount(params) {
    return jfetch.get('/api/search/doctypecount', params);
}

export function getDocCodes() {
    return jfetch.get('/api/searchhelper/getdocumentcodes');
}

export function getBlogFilters() {
    return jfetch.get('/api/searchhelper/getinspirationfilters');
}

export function getVideoFilters() {
    return jfetch.get('/api/searchhelper/getvideofilters');
}

export function getSearchTerms() {
    return jfetch.get('/api/searchhelper/getsearchterms');
}

export function getPodcasts(params) {
    return jfetch.get('/api/search/podcasts', params);
}

export function getPages(params) {
    return jfetch.get('/api/search/pages', params);
}
