// import InspirationGridContainer from '../react/components/inspiration-grid.jsx';
import { getRecipes, getArticles, getChefSeries, getChefSeriesBySearch, getSuccessStories, getSuccessStoriesBySearch, getArticlesBySearch, getRecipesBySearch } from '../apis/inspiration';
import { createElement } from 'react';
import { render } from 'react-dom';

export default class InspirationGrid {
    constructor() {
        this.root = document.querySelector('.js-inspiration-grid-root');

        if (this.root) {
            this.render();
        }
    }

    render() {
        const categories = JSON.parse(this.root.getAttribute('data-categories') || "[]"),
            tags = JSON.parse(this.root.getAttribute('data-tags') || "[]"),
            authors = JSON.parse(this.root.getAttribute('data-authors') || "[]");
        let api, search;
        switch (this.root.getAttribute('data-api-type')) {
            case 'Vollrath.RecipeLanding':
                api = getRecipes;
                search = getRecipesBySearch;
                break;
            case 'Vollrath.TheVollrathBlog':
                api = getArticles;
                search = getArticlesBySearch;
                break;
            case 'Vollrath.SuccessStoriesLandingPage':
                api = getSuccessStories;
                search = getSuccessStoriesBySearch;
                break;
            case 'Vollrath.ChefSeriesLandingPage':
                api = getChefSeries;
                search = getChefSeriesBySearch;
                break;
        }
        import('../react/components/inspiration-grid.jsx')
            .then(InspirationGridContainer => {
                render(createElement(InspirationGridContainer.default, { categories, tags, authors, api, search }), this.root);
            });
    }
}