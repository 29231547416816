import jfetch from '@jontetz/jfetch';

export function getCart() {
    return jfetch.get(`/api/ecommerce/getshoppingcart`);
}

export function addProduct(itemSkuId, itemUnits) {
    return jfetch.post(`/api/ecommerce/additem?itemSkuId=${itemSkuId}&itemUnits=${itemUnits}`);
}

export function updateProduct(itemId, itemUnits) {
    return jfetch.post(`/api/ecommerce/updateitem?itemId=${itemId}&itemUnits=${itemUnits}`);
}

export function deleteProduct(itemId, withSku) {
    return jfetch.post(`/api/ecommerce/removeitem?itemid=${itemId}&withSku=${withSku}`);
}

export function getCartCount() {
    return jfetch.get(`/api/ecommerce/getcartcount`);
}

export function submitOrder(data) {
    return jfetch.post(`/api/ecommerce/submitorder`, data);
}

export function submitConsumerOrder(data) {
    return jfetch.post(`/api/ecommerce/consumerorder`, data);
}

export function getCurrentQty(itemNum) {
    return jfetch.get(`/api/ecommerce/getcurrentqty?itemNum=${itemNum}`);
}