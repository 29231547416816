import React, { Component } from "react";
import CarouselSection from './carousel-section.jsx';
import BlogItem from './blog-item.jsx';

class BlogsCarousel extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.items.length) {
            return (
                <div>
                    <CarouselSection title={this.props.title} titleBold={this.props.titleBold} className={this.props.sectionClass} titleClassName={this.props.sectionTitleClass}>
                        {this.props.items.map((blog, index) => (
                            <BlogItem key={index} {...blog} colClass={this.props.colClass} />
                        ))}
                    </CarouselSection>
                </div>
            );
        }

        return null;
    }
}

export default BlogsCarousel;