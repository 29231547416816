import React, { Component } from "react";
import ReactDOM from 'react-dom';
import Flickity from 'flickity';
import debounce from 'lodash/debounce';

// https://medium.com/yemeksepeti-teknoloji/using-flickity-with-react-a906649b11de
class Carousel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            flickityReady: false,
        };

        this.refreshFlickity = debounce(this.refreshFlickity.bind(this), 500);
    }

    componentDidMount() {

        if (!this.props.options) {
            this.createFlickity();
        }
        else {
            this.flickityInstance = new Flickity(this.flickityNode, this.props.options || {});
        }

        this.setState({
            flickityReady: true
        });
    }

    createFlickity() {
        this.flickityInstance = new Flickity(this.flickityNode, {
            // options...
            pageDots: false,
            cellAlign: 'left',
            contain: true,
            lazyLoad: 2,
            groupCells: true
        });
    }

    refreshFlickity() {
        this.flickityInstance.reloadCells();
        this.flickityInstance.resize();
        this.flickityInstance.updateDraggable();
    }

    componentDidUpdate(prevProps, prevState) {
        const flickityDidBecomeActive = !prevState.flickityReady && this.state.flickityReady;
        const childrenDidChange = prevProps.children.length !== this.props.children.length;

        if (flickityDidBecomeActive || childrenDidChange) {
            this.refreshFlickity();
        }
    }

    componentWillUnmount() {
        this.flickityInstance.destroy();
    }

    renderPortal() {
        if (!this.flickityNode) return null;

        const mountNode = this.flickityNode.querySelector('.flickity-slider');

        if (mountNode) {
            return ReactDOM.createPortal(this.props.children, mountNode);
        }
    }

    render() {
        return [
            <div className="carousel" key="flickityBase" ref={node => (this.flickityNode = node)} />,
            this.renderPortal(),
        ].filter(Boolean);
    }
}

export default Carousel;