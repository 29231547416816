import jfetch from '@jontetz/jfetch';
import toast from './toast';
import { addParamsToUrl } from '../utils/';

class ChatForm {
    constructor(form) {
        this.chat = form;
        this.redirectTo = form.dataset.redirectTo;

        this.isSending = false;
        this.send = this.send.bind(this);
        this.addListeners();
    }

    addListeners() {
        this.chat.addEventListener('submit', this.send);
    }


    send(e) {
        e.preventDefault();


        if (!this.isSending) {
            this.isSending = true;
            const params = {
                pageUrl: window.location.href,
                FormRedirectToUrl: this.chat.dataset.redirectTo,
                },
                fields = this.chat.querySelectorAll('input, textarea'),
                submit = this.chat.querySelector('button');

            for (let i = 0; i < fields.length; i++) {
                const field = fields[i];
                params[field.name] = field.value;
            }

            submit.setAttribute('disabled', 'disabled');
            jfetch.post(this.chat.getAttribute('action'), params)
                .then(json => {
                    if (json.success) {
                        toast.success('Your message has been sent');
                        if (this.redirectTo) window.location = this.redirectTo;
                        else this.chat.reset();
                    }
                    else {
                        toast.error(json.errors[0]);
                    }
                    this.isSending = false;
                    submit.removeAttribute('disabled');
                })
                .catch(error => {
                    console.log(error);
                    this.isSending = false;
                    submit.removeAttribute('disabled');
                    toast.error('There was an issue sending your message. Please try again soon.');
                });
        }
    }
}

export default class ChatForms {
    constructor() {
        this.chat = document.querySelectorAll('.js-chat-form');

        if (this.chat.length) {
            for (let i = 0; i < this.chat.length; i++) {
                new ChatForm(this.chat[i]);
            }
        }
    }
}
