import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { createRoot } from 'react-dom/client';
import { getAllStepsByVersionId, getVersionById, saveUserData, submitGoodFitForm, submitSemiFitForm, submitNonFitForm, submitEmailForm, deleteUserData } from '../../../apis/customer-fit-tool-service';
import { getCurrentContact } from '../../../apis/contact-service';
import StepCard from './step-card.jsx';

function CustomerFitToolApp(props) {
    const [userId, setUserId] = useState(uuidv4());
    const [steps, setSteps] = useState([]);
    const [progressSteps, setProgressSteps] = useState([]);
    const [currentStepIndex, setCurrentStepIndex] = useState();
    const [currentProgressStepIndex, setCurrentProgressStepIndex] = useState();
    const [currentStep, setCurrentStep] = useState([]);
    const [currentVersion, setCurrentVersion] = useState([]);
    const [currentContact, setCurrentContact] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [progress, setProgress] = useState(0);
    const [score, setScore] = useState(0);
    const [hasInteracted, setHasInteracted] = useState(false);

    useEffect(() => {
        getAllStepsByVersionId(props.versionId)
            .then(res => {
                if (res.success) {
                    setSteps(res.data.steps);
                    setCurrentStepIndex(0);

                    setProgressSteps(res.data.steps.filter((item) => item.showProgressBar === true).concat([{}]));//Assuming only steps with the progress bar count towards progress plus one more final step
                    setCurrentProgressStepIndex(0);
                }
            })
            .catch(error => {
                console.log(error)
            });

        getVersionById(props.versionId)
            .then(res => {
                if (res.success) {
                    setCurrentVersion(res.data);
                }
            })
            .catch(error => {
                console.log(error)
            });

        getCurrentContact()
            .then(res => {
                if (res.success) {
                    setCurrentContact(res.data);
                }
            })
            .catch(error => {
                console.log(error)
            });
    }, []);

    useEffect(() => {
        if (steps) {
            let curStep = steps[currentStepIndex];

            if (curStep) {
                setCurrentStep(curStep);

                if (curStep.showProgressBar) {
                    setCurrentProgressStepIndex(progressSteps.findIndex((item) => item == curStep));
                }

                if (selectedValues && selectedValues.length > 0) {
                    saveUserData({ userId: userId, versionId: props.versionId, versionName: currentVersion?.name, selectedValuesJson: JSON.stringify(selectedValues) })
                        .then(res => {
                            if (res.success) {

                            }
                        })
                        .catch(error => {
                            console.log(error)
                        });
                }
                history.pushState(null, document.title, `${window.location.pathname}${currentStepIndex !== 0 ? ('?step=' + currentStepIndex) : ''}`);
            }
        }
    }, [currentStepIndex]);

    useEffect(() => {
        setProgress(Math.round(((currentProgressStepIndex + 1) / progressSteps.length) * 100));
    }, [currentProgressStepIndex]);

    useEffect(() => {
        let score = 0;
        selectedValues.forEach((stepItem) => {
            stepItem.questions.forEach((questionItem) => {
                score += questionItem.pointValue;
            });
        });
        setScore(score);

    }, [selectedValues]);

    useEffect(() => {
        if (hasInteracted) {
            document.querySelector('.js-customer-fit-tool-container').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }
    }, [currentStep]);

    const handleChange = (currentSelection) => {
        setSelectedValues(prevSelectedValues => {
            let updatedValues = prevSelectedValues ? [...prevSelectedValues] : [];
            let stepIndex = updatedValues.findIndex(item => item.stepId === currentStep.id);
            if (stepIndex > -1) {
                let updatedStep = updatedValues[stepIndex];
                let questionIndex = updatedStep.questions.findIndex(index => index.questionId === currentSelection.questionId);
                if (questionIndex > -1) {
                    updatedStep.questions[questionIndex] = currentSelection;
                } else {
                    updatedStep.questions.push(currentSelection);
                }
            } else {
                updatedValues.push({ stepId: currentStep.id, stepName: currentStep.name, questions: [currentSelection] });
            }

            return updatedValues;
        });
    }

    const handlePrevious = () => {
        setHasInteracted(true);
        let previousIndex = getPreviousStepIndex(currentStepIndex - 1);

        if (previousIndex >= 0) {
            setCurrentStepIndex(previousIndex);
        }
    };

    const handleNext = () => {
        setHasInteracted(true);
        let nextIndex = getNextStepIndex(currentStepIndex + 1);

        if (nextIndex >= 0) {
            setCurrentStepIndex(nextIndex);
        }
    };

    const handleJump = (id) => {
        setHasInteracted(true);
        let jumpStepIndex = getNextStepIndex((steps.findIndex(item => item.id === id)));

        if (jumpStepIndex) {
            setCurrentStepIndex(jumpStepIndex);
        }
    };

    const handleSubmit = (submitTypeId, callback) => {
        setHasInteracted(true);
        let userData = selectedValues.find(item => item.stepId === currentStep.id)?.questions;

        var formData = {};
        if (userData) {
            userData.forEach((item) => {
                formData[item.name.replace(/\s+/g, '')] = item.value;
            });

            let userFriendlySelections = selectedValues.map((item, index) => {
                return `${index + 1}. ${item.stepName} <br />${item.questions.map((question, qindex) => { return `-${question.name}: ${question.value}` }).join('<br />')}`;
            });

            formData["Notes"] = userFriendlySelections.join('\n\n');
            formData["UserId"] = userId;
            formData["VersionId"] = currentVersion?.id ?? 0;
            formData["VersionName"] = currentVersion?.name;

            switch (submitTypeId) {
                case 1: //good fit form
                    submitGoodFitForm(formData)
                        .then(res => {
                            if (res.success) {
                                deleteData(userId);
                                handleNext();
                            } else {
                                console.log(res.errors);
                            }
                            callback();
                        })
                        .catch(error => {
                            console.log(error);
                            callback();
                        });
                    break;

                case 2: //semi fit form
                    submitSemiFitForm(formData)
                        .then(res => {
                            if (res.success) {
                                deleteData(userId);
                                handleNext();
                            } else {
                                console.log(res.errors);
                            }
                            callback();
                        })
                        .catch(error => {
                            console.log(error);
                            callback();
                        });
                    break;

                case 3: //non fit form
                    submitNonFitForm(formData)
                        .then(res => {
                            if (res.success) {
                                deleteData(userId);
                                handleNext();
                            } else {
                                console.log(res.errors);
                            }
                            callback();
                        })
                        .catch(error => {
                            console.log(error);
                            callback();
                        });
                    break;

                case 4: //email form
                    submitEmailForm(formData)
                        .then(res => {
                            if (res.success) {
                                deleteData(userId);
                                handleNext();
                            } else {
                                console.log(res.errors);
                            }
                            callback();
                        })
                        .catch(error => {
                            console.log(error);
                            callback();
                        });
                    break;
            }
        }
    };

    const getNextStepIndex = (index) => {
        if (steps) {
            if (index >= steps.length) {
                return -1;
            }

            let curStep = steps[index];
            if (score >= curStep.scoreRequirement) {
                return index;
            } else {
                return getNextStepIndex(index + 1);
            }
        }
    }

    const getPreviousStepIndex = (index) => {
        if (steps) {
            if (index < 0) {
                return -1;
            }

            let curStep = steps[index];
            if (score >= curStep.scoreRequirement) {
                return index;
            } else {
                return getPreviousStepIndex(index - 1);
            }
        }
    }

    const deleteData = (id) => {
        deleteUserData(id)
            .then(res => {
                if (res.success) {
                    
                }
            })
            .catch(error => {
                console.log(error)
            });
    }

	return (
        <StepCard step={currentStep} progress={progress} currentContact={currentContact} userId={userId} userSelections={selectedValues} selectedStepValues={selectedValues.find(item => item.stepId === currentStep.Id)?.questions} onChange={(e) => handleChange(e)} onPrevious={handlePrevious} onNext={handleNext} onJump={handleJump} onSubmit={handleSubmit} />
	);
}

export default CustomerFitToolApp;

const rootNode = document.querySelector('.js-customer-fit-tool-container');
const versionId = rootNode?.dataset?.versionId ?? 0;

if (rootNode) {
    const root = createRoot(rootNode);
    root.render(
        <CustomerFitToolApp versionId={versionId} />
    );
}