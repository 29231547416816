import React, { Component } from "react";
import Lazyload from './lazyload.jsx';
import CSSTransition from 'react-transition-group/CSSTransition';
import { productFallbackImage } from '../../config.json';

class Genus extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imageVisible: false,
            url: props.pageUrl + props.queryParams
        };

        this.handleLazyloadVisible = this.handleLazyloadVisible.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.queryParams !== prevProps.queryParams) {
            this.setState({
                url: this.props.pageUrl + this.props.queryParams
            });
        }
    }

    handleLazyloadVisible() {
        this.setState({
            imageVisible: true
        });
    }

    render() {
        let features,
            image,
            discontinued;

        if (this.props.features && this.props.features.length) {
            features = <ul className="full-card-list">
                {this.props.features.slice(0, 3).map((feature, index) => (
                    <li>{feature}</li>
                ))}
            </ul>;
        }

        if (this.props.thumbnail) {
            image = <img srcSet={`${this.props.thumbnail} 1x, ${this.props.thumbnailHighRes} 2x`} alt={this.props.introTitle + ' Thumbnail'} />;
        }
        else {
            image = <img srcSet={productFallbackImage} alt={this.props.introTitle + ' Thumbnail'} />;
        }

        discontinued = this.props.discontinued ? 'discontinued-product' : '';

        return (
            <div id={this.props.id} className={this.props.colClass}>
                <article className={`full-card ${discontinued}`}>
                    <div className="full-card-media">
                        <CSSTransition in={this.state.imageVisible} timeout={250} classNames="fade">
                            <Lazyload height="220px" rootMargin="100px" onVisible={this.handleLazyloadVisible}>
                                <a id={`trending-product-img-${this.props.id}`} href={this.state.url} tabIndex="-1">
                                    {image}
                                </a>
                            </Lazyload>
                        </CSSTransition>
                    </div>
                    <div className="full-card-info">
                        <h3 className="full-card-title"><a id={`trending-product-title-${this.props.id}`} href={this.state.url}>{this.props.introTitle}</a></h3>
                        {features}
                        <span className="full-card-count">{this.props.numProducts} Products</span>
                    </div>
                </article>
            </div>
        );
    }
}

Genus.defaultProps = {
    colClass: 'col-lg-3 col-sm-6',
    queryParams: ''
};

export default Genus;