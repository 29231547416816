import { createElement } from 'react';
import { render } from 'react-dom';


export default class PhotoGallery {
    constructor() {
        this.containers = document.querySelectorAll('.js-photo-gallery-widget');

        if (this.containers.length) {
            this.containers.forEach((item) => {
                this.start(item);
            })

        }
    }

    start(container) {
        const url = container.getAttribute('data-url'),
            title = container.getAttribute('data-title'),
            desc = container.getAttribute('data-desc'),
            position = container.getAttribute('data-position'),
            bullets = container.getAttribute('data-bullets');

        import('../react/components/photo-gallery.jsx')
            .then(PhotoGallery => {
                render(createElement(PhotoGallery.default, { url, title, desc, position, bullets }), container);
            });
    }
}