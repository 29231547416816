import { createElement } from 'react';
import { render } from 'react-dom';
import { getCartCount } from '../apis/shopping-cart';

export default class ShoppingCartActions {
    constructor() {
        this.container = document.querySelectorAll('.js-add-to-cart');
        this.cart = document.querySelector('.header-cart-btn-number');

        if (this.container.length) {
            this.container.forEach(c => {
                this.start(c);
            });

        }
        if (this.cart) {
            this.updateNumbers();
        }
    }

    start(e) {
        const sku = e.getAttribute('data-sku-id'),
            inCart = e.getAttribute('data-in-cart'),
            inventory = e.getAttribute('data-inventory'),
            itemNum = e.getAttribute('data-item-num'),
            isInStock = e.getAttribute('data-is-in-stock'),
            authenticated = e.getAttribute('data-authenticated');
        import('../react/components/shopping-cart-actions.jsx')
            .then(ShoppingCartActions => {
                render(createElement(ShoppingCartActions.default, { sku, itemNum, inCart, inventory, isInStock, authenticated }), e);
            });
    }

    updateNumbers() {
        const number = document.querySelector('.js-cart-number');

        getCartCount()
            .then(data => {
                number.innerHTML = data;
            })
            .catch(error => {
                console.log(error);

            });
    }
}