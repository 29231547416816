import React, { useState, useEffect } from 'react';

const Dropdown = props => {
	const [htmlId, setHtmlId] = useState(`question-${props.question.id}`);
	const [selectedValue, setSelectedValue] = useState();
	const [cssClass, setCssClass] = useState(props.cssClass ?? '');

	useEffect(() => {
		setSelectedValue(props.selectedValue)
	}, [props.selectedValue]);

	const handleChange = (e) => {
		setSelectedValue(e.target.value);
		props.onChange(e.target.value);
	};

	return (
		<>
			<div className={`cft-dropdown ${cssClass}  ${props.validationCssClass}`}>
				{props.question.showLabel && <label for={htmlId} >{props.question.label ?? props.question.name}</label>}
				{props.validationMessage && props.validationCssClass && <span className="validation-message">{props.validationMessage}</span>}
				<select id={htmlId} className={`form-control ${props.validationCssClass}`} value={selectedValue} onBlur={handleChange}>
					{props.question.answers && props.question.answers.map((item, index) => {
						return <option value={item.isDefault ? '' : item.value}>{item.value}</option>
					})}
				</select>
			</div>

		</>
	);
}

export default Dropdown;