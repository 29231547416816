import { createElement } from 'react';
import { render } from 'react-dom';

export default class QuizContainer {
    constructor() {
        this.container = document.querySelector('.js-quiz-container');

        if (this.container) {
            this.start();
        }
    }

    start() {
        const quizID = this.container.getAttribute('data-quiz'),
            title = this.container.getAttribute('data-title'),
            desc = this.container.getAttribute('data-desc'),
            img = this.container.getAttribute('data-img');

        import('../react/components/elearning/elearn-container.jsx')
            .then(QuizContainer => {
                render(createElement(QuizContainer.default, { quizID, title, desc, img }), this.container);
            });
    }
}