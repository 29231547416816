import React, { Component } from "react";
import MosaicItem from './mosaic-item.jsx';
import Magnific from './magnific.jsx';

class Mosaic extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            modalIndex: 0
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    get galleryItems() {
        const items = this.props.items.filter(i => !!i.PageVideoData);
        return items.map(i => {
            if (i.PageVideoData.Url.includes('mp4')) {
                return {
                    type: 'video',
                    src: i.PageVideoData.Url,
                    id: i.NodeId
                };
            } else {
                return {
                    type: 'iframe',
                    src: i.PageVideoData.Url,
                    id: i.NodeId
                };
            }
        });
    }

    handleClick(e) {
        let link = e.target;
        while (link.tagName !== 'A') {
            link = link.parentElement;
        }

        const index = +link.getAttribute('data-index'),
            item = this.props.items[index];

        if (item.PageVideoData) {
            e.preventDefault();

            const videoIndex = this.galleryItems.findIndex(g => g.id === item.NodeId);
            console.log('index', videoIndex);
            this.setState({
                showModal: true,
                modalIndex: videoIndex
            });
        }
    }

    handleClose() {
        this.setState({
            showModal: false
        });
    }

    render() {
        return (
            <div className={`mosaic ${this.props.className}`}>
                {this.props.items.map((item, index) => (
                    <MosaicItem key={index} {...item} index={index} width={this.props.columns[index]} handleClick={this.handleClick} />
                ))}

                <Magnific items={this.galleryItems} show={this.state.showModal} onClose={this.handleClose} index={this.state.modalIndex} />
            </div>
        );
    }
}

Mosaic.defaultProps = {
    columns: [6,6,4,4,4]
};

export default Mosaic;