import { createElement } from 'react';
import { render } from 'react-dom';
import SingleVideo from '../react/components/single-video.jsx';

export default class Forms {
    constructor() {
        this.renderRegistrationForm();
        this.renderInventorsForm();
        this.renderPotentialSupplierRegistrationForm();
        this.renderPotentialDomesticCarrierRegistrationForm();
        this.renderServingSystemsForm();
        this.renderVollrathUniversityEventRegistrationForm();
        this.renderVollrathUniversityEventConfirmationForm();
        this.renderVollrathUniversityEventConfirmedInfo();
        this.renderVollrathUniversityLandingVideo();
    }

    renderRegistrationForm() {
        const container = document.querySelector('.js-product-registration-root');
        if (container) {
            const redirectTo = container.dataset.redirectTo;
            import('../react/components/forms/product-registration.jsx')
                .then(ProductRegistration => {
                    render(createElement(ProductRegistration.default, { redirectTo }), container);
                });
        }
    }

    renderInventorsForm() {
        const container = document.querySelector('.js-inventors-submission-form-root');
        if (container) {
            const agreementPolicy = container.dataset.agreementPolicy,
                redirectTo = container.dataset.redirectTo;
            import('../react/components/forms/inventors-submission.jsx')
                .then(InventorsSubmission => {
                    render(createElement(InventorsSubmission.default, { agreementPolicy, redirectTo }), container);
                });
        }
    }

    renderPotentialSupplierRegistrationForm() {
        const container = document.querySelector('.js-potential-supplier-registration-form-root');
        if (container) {
            const redirectTo = container.dataset.redirectTo;
            import('../react/components/forms/potential-supplier-registration.jsx')
                .then(PotentialSupplierRegistration => {
                    render(createElement(PotentialSupplierRegistration.default, { redirectTo }), container);
                });
        }
    }

    renderPotentialDomesticCarrierRegistrationForm() {
        const container = document.querySelector('.js-potential-carrier-registration-form-root');
        if (container) {
            const redirectTo = container.dataset.redirectTo;
            import('../react/components/forms/potential-carrier-registration.jsx')
                .then(PotentialCarrierRegistration => {
                    render(createElement(PotentialCarrierRegistration.default, { redirectTo }), container);
                });
        }
    }

    renderServingSystemsForm() {
        const container = document.querySelector('.js-serving-system-form-root');
        if (container) {
            const pageName = document.title,
                pageUrl = container.dataset.pageUrl,
                redirectTo = container.dataset.redirectTo;

            import('../react/components/forms/serving-systems.jsx')
                .then(ServingSystemsForm => {
                    render(createElement(ServingSystemsForm.default, { pageName, pageUrl, redirectTo }), container);
                });
        }
    }

    renderVollrathUniversityEventRegistrationForm() {
        const container = document.querySelector('.js-event-register');
        if (container) {
            const pageName = document.title,
                redirectTo = container.dataset.redirectTo,
                isAuthenticated = container.dataset.isAuthenticated,
                unAuthenticatedContent = container.dataset.unAuthenticatedContent,
                registered = container.dataset.registered,
                eventCode = container.dataset.eventCode,
                userEmail = container.dataset.userEmail,
                contact = JSON.parse(container.dataset.contact),
                dealer = container.dataset.dealer,
                title = container.dataset.title,
                deadlineReached = container.dataset.deadlineReached,
                deadlineReachedText = container.dataset.deadlineReachedText;

            import('../react/components/forms/vu-event-registration.jsx')
                .then(VollrathUniversityEventRegistrationForm => {
                    render(createElement(VollrathUniversityEventRegistrationForm.default, { pageName, redirectTo, isAuthenticated, unAuthenticatedContent, registered, eventCode, userEmail, contact, dealer, title, deadlineReached, deadlineReachedText }), container);
                });
        }
    }
    renderVollrathUniversityEventConfirmationForm() {
        const container = document.querySelector('.js-event-confirm');
        if (container) {
            const pageName = document.title,
                eventId = container.dataset.eventId,
                eventCode = container.dataset.eventCode,
                redirectTo = container.dataset.redirectTo,
                title = container.dataset.title;

            import('../react/components/forms/vu-event-confirmation.jsx')
                .then(VollrathUniversityEventConfirmationForm => {
                    render(createElement(VollrathUniversityEventConfirmationForm.default, { pageName, eventId, eventCode, redirectTo, title }), container);
                });
        }
    }

    renderVollrathUniversityEventConfirmedInfo() {
        const container = document.querySelector('.js-event-edit-info');
        if (container) {
            const eventId = container.dataset.eventId,
                email = container.dataset.userEmail;

                import('../react/components/forms/vu-event-confirmed-info.jsx')
                .then(VollrathUniversityEventConfirmedInfo => {
                    render(createElement(VollrathUniversityEventConfirmedInfo.default, { eventId, email }), container);
                });
        }
    }

    renderVollrathUniversityLandingVideo() {
        const container = document.querySelector('.js-event-video');
        if (container) {
            const items = JSON.parse(container.dataset.items);
            render(createElement(SingleVideo
                , { items }), container);
        }
    }
}