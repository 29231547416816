import toast from './toast';
import Clipboard from 'clipboard';

export default class Clippy {
    constructor() {
        this.clips = document.querySelectorAll('.js-clipboard');

        if (this.clips.length) {
            this.addListeners();
        }
    }

    addListeners() {
        const clipboard = new Clipboard('.js-clipboard');

        clipboard.on('success', e => {
            toast.success('Copied to clipboard')
        });
    }
}