import InspirationSectionComponent from '../react/components/inspiration-section.jsx';
import { createElement } from 'react';
import { render } from 'react-dom';

export default class InspirationSection {
    constructor() {
        this.container = document.querySelector('.js-inspiration-section');

        if (this.container) {
            this.start();
        }
    }

    start() {
        const title = this.container.getAttribute('data-title'),
            titleBold = this.container.getAttribute('data-title-bold'),
            inspiration = JSON.parse(this.container.getAttribute('data-inspiration'));

        render(createElement(InspirationSectionComponent, { title, titleBold, inspiration }), this.container);
    }
}