import { createElement } from 'react';
import { render } from 'react-dom';

export default class QualityControlContainer {
    constructor() {
        this.container = document.querySelector('.js-quality');

        if (this.container) {
            this.start();
        }
    }

    start() {
        const 
            email =  this.container.getAttribute('data-email'),
            pageGuid =  this.container.getAttribute('data-guid'),
            submitted = this.container.getAttribute('data-submitted');
    

        import('../react/components/quality-control.jsx')
            .then(QualityControl => {
                render(createElement(QualityControl.default, { email, pageGuid, submitted }), this.container);
            });
    }
}