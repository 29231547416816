import { createElement } from 'react';
import { render } from 'react-dom';

export default class SerVueCarouselContainer {
    constructor() {
        this.container = document.querySelector('.js-ser-vue-carousel');

        if (this.container) {
            this.start();
        }
    }

    start() {

        const
            imagesText = this.container.getAttribute('data-images-text'),
            isContinuosScrollEnabled = this.container.getAttribute('data-is-continous-scroll-enabled');

        import('../react/components/ser-vue-carousel.jsx')
            .then(SerVueCarousel => {
                render(createElement(SerVueCarousel.default, {
                    imagesText,
                    isContinuosScrollEnabled
                }),
                this.container);
            });
    }
}