import { createElement } from 'react';
import { render } from 'react-dom';

export default class VirtualEventContainer {
    constructor() {
        this.container = document.querySelector('.js-virtual-event');

        if (this.container) {
            this.start();
        }
    }

    start() {
        const event = {
            email: this.container.getAttribute('data-email'),
            eventGuid: this.container.getAttribute('data-event-guid'),
            registered: this.container.getAttribute('data-registered'),
            streamUrl: this.container.getAttribute('data-stream-url'),
            eventName: this.container.getAttribute('data-event-name'),
            startDate: this.container.getAttribute('data-start'),
            endDate: this.container.getAttribute('data-end'),
            contact: JSON.parse(this.container.getAttribute('data-contact'))
        }

        import('../react/components/virtual-event.jsx')
            .then(VirtualEvent => {
                render(createElement(VirtualEvent.default, { event }), this.container);
            });
    }
}