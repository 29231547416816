import React, { useState, useEffect } from 'react';

const RadioSlider = props => {
    const [htmlId, setHtmlId] = useState(`question-${props.question?.id}`);
    const [answers, setAnswers] = useState(props.question?.answers);
    const [groupedAnswers, setGroupedAnswers] = useState([]);
    const [singleAnswers, setSingleAnswers] = useState([]);
    const [selectedValue, setSelectedValue] = useState();
    const [selectedRangeValue, setSelectedRangeValue] = useState(-1);
    const [cssClass, setCssClass] = useState(props.cssClass ?? '');

    useEffect(() => {
        if (answers) {
            setGroupedAnswers(answers.filter(item => item.isGrouped === true));
            setSingleAnswers(answers.filter(item => item.isGrouped === false));
        }

        setAllSelectedValues(props.selectedValue);

        loadSliderStyles();
    }, []);

    useEffect(() => {
        let inputEle = document.getElementById(htmlId);
        if (selectedRangeValue < 0) {
            inputEle.classList.add('empty');
        } else {
            inputEle.classList.remove('empty');
        }

        loadSliderStyles();
    }, [selectedRangeValue]);

    const handleGroupedAnswerChange = (e) => {

        let actualSelectedValue = groupedAnswers[e.target.value].value;

        setSelectedRangeValue(e.target.value);
        setSelectedValue(actualSelectedValue);
        props.onChange(actualSelectedValue);
    };

    const handleSingleAnswerChange = (e) => {
        setSelectedValue(e.target.value);
        setSelectedRangeValue(-1);
        props.onChange(e.target.value);
    };

    const handleRangeClick = (e) => {
        if (selectedRangeValue == -1) { //Only triggers when the first value is selected after page load. Change event doesn't trigger because the control defaults to the first spot.
            handleGroupedAnswerChange(e);
        }
    };

    const loadSliderStyles = () => {
        let ele = document.getElementById(htmlId);
        let baseColor = 'lightgrey';
        let highlightColor = '#003087';

        let rangeValue = selectedRangeValue == -1 ? 0 : selectedRangeValue;
        let sliderPercent = (rangeValue - ele.min) / (ele.max - ele.min) * 100;

        ele.style.background = `linear-gradient(to right, ${highlightColor} 0%, ${highlightColor} ${sliderPercent}%, ${baseColor} ${sliderPercent}%, ${baseColor} 100%)`;

        let ticks = ele.parentElement.getElementsByClassName('tick');
        Array.from(ticks).forEach((item, index) => {
            if (item.dataset.value <= ele.value) {
                item.classList.add('selected');
            } else {
                item.classList.remove('selected');
            }
        });
    }

    const setAllSelectedValues = (val) => {
        setSelectedValue(val);

        let selectedAnswer = answers.find(item => item.value == val);

        if (selectedAnswer && selectedAnswer.IsGrouped) {
            let grouped = answers.filter(item => item.isGrouped === true);
            let index = grouped.findIndex((item) => item == selectedAnswer);
            setSelectedRangeValue(index);
        }
    }

    const calculateGroupColumnSize = (totalCount, groupCount) => {
        return Math.max(Math.floor(12 / totalCount), 2) * groupCount;
    }

    const calculateSingleColumnSize = (totalCount, groupCount, singleCount) => {
        let groupSize = calculateGroupColumnSize(totalCount, groupCount);

        let singleSizePool = 12 - groupSize;

        if (singleSizePool > 0) {
            return singleSizePool / singleCount;
        }

        return 12;
    }

    return (
        <>
            {props.question &&
                <div className={`cft-radio-slider ${cssClass} ${props.validationCssClass}`}>
                    {props.validationMessage && props.validationCssClass &&
                        <div className="row">
                            <div className="col">
                                <span className="validation-message">{props.validationMessage}</span>
                            </div>
                        </div>
                    }
                    <div className="row">
                        {groupedAnswers &&
                            <div className={`grouped-list col-md-${calculateGroupColumnSize(answers.length, groupedAnswers.length)} col`}>
                                <input id={htmlId} type="range" min="0" max={groupedAnswers.length - 1} value={selectedRangeValue} onInput={handleGroupedAnswerChange} onMouseDown={handleRangeClick} />
                                <div className="ticks">
                                    {groupedAnswers.map((item, index) => {
                                        return <span className="tick" data-value={index}><span className="tick-content">{item.value}</span></span>
                                    })}
                                </div>
                            </div>
                        }
                        {singleAnswers &&
                            <div className={`singles-list text-center col-md-${calculateSingleColumnSize(answers.length, groupedAnswers.length, singleAnswers.length)} col-6`}>
                                {singleAnswers && singleAnswers.map((item, index) => {
                                    return (
                                        <>
                                            <div>
                                                <label>
                                                    <input type="radio" value={item.value} name={htmlId} checked={selectedValue === item.value} onClick={handleSingleAnswerChange} />
                                                    <span className="radio-label">{item.value}</span>
                                                </label>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default RadioSlider;