import React, { Component } from "react";
import Section from './section.jsx';
import CarouselSection from './carousel-section.jsx';
import LoaderSection from './loader-section.jsx';
import debounce from 'lodash/debounce';

class CarouselSectionSwitcher extends Component {
    constructor(props) {
        super(props);

        this.state = {
            windowWidth: window.outerWidth
        };

        this.handleResize = debounce(this.handleResize.bind(this), 250);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize, { passive: true });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, { passive: true });
    }

    handleResize() {
        this.setState({
            windowWidth: window.outerWidth
        });
    }

    render() {
        let loader;
        if (this.props.showLoader) {
            loader = <LoaderSection show={true} />;
        }
        if (this.state.windowWidth <= this.props.switchWidth) {
            return (
                <CarouselSection title={this.props.title} titleBold={this.props.titleBold} link={this.props.link} linkText={this.props.linkText}>
                    {this.props.carousel}
                    {loader}
                </CarouselSection>
            );
        }
        else {
            return (
                <Section title={this.props.title} titleBold={this.props.titleBold} link={this.props.link} linkText={this.props.linkText}>
                    {this.props.section}
                    {loader}
                </Section>
            );
        }
    }
}

CarouselSectionSwitcher.defaultProps = {
    switchWidth: 575,
    showLoader: false
};

export default CarouselSectionSwitcher;