
//Site Specific JS

import VMSMenu from './components/vms-menu.js';
import SearchMenu from './components/search-menu.js';
import SearchContainer from './components/search';
import AB1200 from './components/ab-1200-app';

new VMSMenu();
new SearchMenu();
new SearchContainer();
new AB1200();