
export default class UploadDocuments {
    constructor() {
        this.dealerForm();
    }

    dealerForm() {
        const dealerForm = document.getElementById('dealer-form');

        if (dealerForm) {

            const inputElement = document.getElementById("File");

            if (inputElement) {
                inputElement.addEventListener("change", handleFiles, false);

                function handleFiles() {
                    const fileList = this.files;
                    let output = document.getElementById('fileList');

                    let children = "";
                    for (var i = 0; i < fileList.length; ++i) {
                        children += '<li>' + fileList.item(i).name + '</li>';
                    }
                    output.innerHTML = '<ul>' + children + '</ul>';

                    document.querySelector('.upload .intro-blk-copy').classList.add('files-uploaded');
                }
            }
        }
    }
}