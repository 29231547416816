import { createElement } from 'react';
import { render } from 'react-dom';
import ProductsCarousel from '../react/components/products-carousel.jsx';
import { getPartProducts } from '../apis/products';

export default class Part {
    constructor() {
        this.part = document.querySelector('.js-part');

        if (this.part) {
            this.id = this.part.getAttribute('data-id');
            this.partProducts = this.part.querySelector('.js-part-products');
            this.start();
        }
    }

    start() {
        // recentlyViewedProducts.add(this.id);
        this.renderPartProducts();
    }

    renderPartProducts() {
        const id = this.partProducts.getAttribute('data-id'),
            title = this.partProducts.getAttribute('data-title'),
            titleBold = this.partProducts.getAttribute('data-title-bold');
        render(createElement(ProductsCarousel, {
            title,
            titleBold,
            params: id,
            api: getPartProducts,
            colClass: 'col-lg-3 col-xs-6'
        }), this.partProducts);
    }
}