import SearchMenuContainer from '../react/components/search-menu.jsx';
import SearchMenuMobile from '../react/components/search-menu-mobile.jsx';
import { createElement } from 'react';
import { render } from 'react-dom';

export default class SearchMenu {
    constructor() {
        this.container = document.querySelector('.js-search-menu-VMS-root');
        this.mobileContainer = document.querySelector('.js-mobile-search-VMS-root');

        if (this.container) {
            this.start();
        }
    }

    start() {
        render(createElement(SearchMenuContainer), this.container);
        render(createElement(SearchMenuMobile), this.mobileContainer);
    }
}