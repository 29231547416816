import { logoutUser } from '../apis/forms';
import toast from './toast';

export default class Logout {
    constructor() {
        this.buttons = document.querySelectorAll('.js-logout');

        if (this.buttons.length) {
            this.addListeners();
        }
    }

    addListeners() {
        this.clickBind = this.click.bind(this);
        for (let i = 0; i < this.buttons.length; i++) {
            this.buttons[i].addEventListener('click', this.clickBind);
        }
    }

    click(e) {
        e.preventDefault();
        let redirectUrl = document.referrer;
        let data = {
            returnUrl: redirectUrl
        }
        logoutUser()
            .then(json => {
                console.log(json);
                toast.success('You are now logged out');

                // Move to a new location or you can do something else
                window.location.href = window.location.href;
            })
            .catch(error => {
                toast.success('You are now logged out');

                // Move to a new location or you can do something else
                window.location.href = window.location.origin;
            });
    }

    destroy() {
        if (this.buttons.length) {
            for (let i = 0; i < this.buttons.length; i++) {
                this.buttons[i].removeEventListener('click', this.clickBind);
            }
        }
    }
}