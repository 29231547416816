// import WishlistLanding from '../react/pages/wishlist-landing.jsx';
import { createElement } from 'react';
import { render } from 'react-dom';

export default class WishlistContainer {
    constructor() {
        this.container = document.querySelector('.js-wishlist-root');

        if (this.container) {
            this.start();
        }
    }

    start() {      
        const title = this.container.getAttribute('data-title');
        import('../react/pages/wishlist-landing.jsx')
            .then(WishlistLanding => {
                render(createElement(WishlistLanding.default, { title }), this.container);
            });
    }
}