import { createElement } from 'react';
import { render } from 'react-dom';
import { getCustomContent, getCustomContentEvents, getProductsForSale } from "../apis/custom-content.js"

export function getApi(apiType) {
    let api = null;
    switch (apiType) {
        case 'CustomContent':
            api = getCustomContent;
            break;
        case 'Events':
            api = getCustomContentEvents;
            break;
        case 'ProdctsForSale':
            api = getProductsForSale;
            break;
        default:
            api = getCustomContent;
            break;
    }

    return api;
}

export default class FilterableGridContainer {
    constructor() {
        this.containers = document.querySelectorAll('.js-filterable-grid');

        if (this.containers.length) {
            this.containers.forEach((item) => {
                this.start(item);
            })

        }
    }

    start(item) {
        const showFilter = item.getAttribute('data-show-filter').toLowerCase() === 'true';
        const nodeId = item.getAttribute('data-node-id');
        const categories = JSON.parse(item.getAttribute('data-categories') || "[]"),
            cardType = item.getAttribute('data-card-type').toLowerCase() === 'true',
            noPagination = item.getAttribute('data-no-pagination').toLowerCase() === 'true',
            children = item.getAttribute('data-child-count'),
            bigCards = item.getAttribute('data-big-cards').toLowerCase() === 'true',
            cardsPerPage = item.getAttribute('data-per-page');

        const api = getApi(item.getAttribute('data-api-type'));
        
        import('../react/components/filterable-grid.jsx')
            .then(FilterableGrid => {
                render(createElement(FilterableGrid.default, { showFilter, api, nodeId, categories, cardType, noPagination, children, bigCards, cardsPerPage }), item);
            });
    }
}