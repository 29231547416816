import { createElement } from 'react';
import { render } from 'react-dom';
import Mosaic from '../react/components/mosaic.jsx';

export default class Product {
    constructor() {
        this.mosaics = document.querySelectorAll('.js-mosaic');

        if (this.mosaics.length) {
            this.start();
        }
    }

    start() {
        [...this.mosaics].forEach(mosaic => {
            this.renderMosaic(mosaic);
        });
    }

    renderMosaic(container) {
        const items = JSON.parse(container.getAttribute('data-items')),
            className = container.getAttribute('data-class');
        const props = {
            items,
            className
        };
        if (container.getAttribute('data-columns')) {
            props.columns = JSON.parse(container.getAttribute('data-columns'));
        }
        if(items && items.length) render(createElement(Mosaic, props), container);
    }
}