import React, { Component } from 'react';
import SearchMenuBase from './search-menu-base.jsx';


class SearchMenuMobileContainer extends SearchMenuBase {
    constructor(props) {
        super(props);

    }

    render() {

        return (
            <form action="/search" method="get" onSubmit={this.handleFormSubmit} className="search-field-mobile">
                <div className="search-field-mobile-container">
                    <label className="sr-only" htmlFor="search-field-mobile">Search</label>
                    <input ref={this.searchInput} className="search-field-mobile-input" id="search-field-mobile" type="text" name="q" placeholder="Search..." onChange={this.handleChange} onFocus={this.handleFocus} onBlur={this.handleBlur} required autoCapitalize="none" autoCorrect="off" />
                    <button className="search-field-mobile-btn">
                        <svg className="icon" width="30" height="30"><use xlinkHref="#icon-search"></use></svg>
                        <span className="sr-only">Search</span>
                    </button>
                </div>
            </form>
        );
    }
}

export default SearchMenuMobileContainer;