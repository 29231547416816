export default class Register {
    constructor() {

        this.container = document.getElementById('registration-options');

        this.dealerBox = document.querySelector('input[id="dealer-box"]');
        this.salesBox = document.querySelector('input[id="sales-box"]');
        this.userBox = document.querySelector('input[id="user-box"]');

        this.userForm = document.getElementById("consumer-form");
        this.dealerForm = document.getElementById("dealer-form");
        this.salesForm = document.getElementById("sales-form");

        if (this.container) {
            this.addListeners();
            this.checkboxes = document.querySelectorAll('input[type="radio"]');
            this.setLocalStorage();
        }
    }

    addListeners() {
        if (this.dealerBox) {
            this.dealerBox.addEventListener("change", e => {
                this.handleCheck(e);
            });
        }
        if (this.salesBox) {
            this.salesBox.addEventListener("change", e => {
                this.handleCheck(e);
            });
        }
        if (this.userBox) {
            this.userBox.addEventListener("change", e => {
                this.handleCheck(e);
            });
        }
    }

    handleCheck(e) {
        e.preventDefault();
        const target = e.target;

        switch (target.id) {
            case "dealer-box":
                if (target.checked) {
                    this.dealerForm.style.display = "block";
                    this.salesForm.style.display = "none";
                    this.userForm.style.display = "none";
                } else {
                    this.dealerForm.style.display = "none";
                }
                break;
            case "sales-box":
                if (target.checked) {
                    this.salesForm.style.display = "block";
                    this.dealerForm.style.display = "none";
                    this.userForm.style.display = "none";
                } else {
                    this.salesForm.style.display = "none";
                }
                break;
            case "user-box":
                if (target.checked) {
                    this.userForm.style.display = "block";
                    this.salesForm.style.display = "none";
                    this.dealerForm.style.display = "none";
                } else {
                    this.userForm.style.display = "none";
                }
                break;
        }
    }

    setLocalStorage() {
        let getStorage = localStorage.getItem("checkbox");

        if (getStorage === null) {
            this.checkboxes.forEach(c => {
                c.addEventListener("click", e => {
                    localStorage.setItem("checkbox", c.id);
                });
            });
        } else {
            const checkboxId = localStorage.getItem("checkbox");
            const checkbox = document.getElementById(checkboxId);

            checkbox.checked = true;

            if (checkbox.id === "user-box") this.userForm.style.display = "block";

            if (checkbox.id === "dealer-box") this.dealerForm.style.display = "block";

            if (checkbox.id === "sales-box") this.salesForm.style.display = "block";
        }
    }
}
