import React, { Component } from "react";

export default function Section(props) {
    let link;

    if (props.link) link = <a className="section-header-link" href={props.link}>{props.linkText}</a>;

    return (
        <section className={`container section ${props.className ? props.className : ''}`}>
            <header className={`section-header ${props.titleClassName ? props.titleClassName : ''}`}>
                <h3 className="section-title">{props.title} <span>{props.titleBold}</span></h3>
                <div className="spacer"></div>
                {link}
            </header>
            {props.children}
        </section>
    );
}