import React, { Component } from "react";
import Section from './section.jsx';
import Carousel from './carousel.jsx';

class CarouselSection extends Component {
    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        
    }

    render() {
        return (
            <Section {...this.props}>
                <Carousel>
                    {this.props.children}
                </Carousel>
            </Section>
        );
    }
}

export default CarouselSection;