import jfetch from '@jontetz/jfetch';

export function getCustomContent(data) {
    return jfetch.get('/api/customcontent/getcustomcards', data);
}

export function getCustomContentEvents(data) {
    return jfetch.get('/api/customcontent/getcustomevents', data);
}

export function getProductsForSale(data) {
    return jfetch.get('/api/customcontent/getproductsforsale', data);
}