import { createElement } from 'react';
import { render } from 'react-dom';

export default class SubmitOrder {
    constructor() {
        this.container = document.querySelector('.js-submit-order');

        if (this.container) {
            this.start();
        }
    }

    start() {
        const isDealer = this.container.getAttribute('data-dealer'),
            fName = this.container.getAttribute('data-fName'),
            lName = this.container.getAttribute('data-lName'),
            email = this.container.getAttribute('data-email'),
            phone = this.container.getAttribute('data-phone'),
            address = this.container.getAttribute('data-address'),
            city = this.container.getAttribute('data-city'),
            state = this.container.getAttribute('data-state'),
            postal = this.container.getAttribute('data-postal');

        import('../react/components/submit-order.jsx')
            .then(SubmitOrder => {
                render(createElement(SubmitOrder.default, {isDealer, fName, lName, email, phone, address, city, state, postal}),  this.container);
            });
    }
}