import jfetch from '@jontetz/jfetch';

export function getArticles(data) {
    return jfetch.get('/api/inspiration/article', data);
}

export function getCustomArticles(data) {
    return jfetch.get('/api/inspiration/getcustomarticles', data);
}

export function getChefSeries(data) {
    return jfetch.get('/api/inspiration/chefseries', data);
}

export function getChefSeriesBySearch(data) {
    return jfetch.get('/api/search/chefseries', data);
}

export function getSuccessStories(data) {
    return jfetch.get('/api/inspiration/successstories', data);
}

export function getSuccessStoriesBySearch(data) {
    return jfetch.get('/api/search/successstories', data);
}

export function getRecipes(data) {
    return jfetch.get('/api/inspiration/recipe', data);
}

export function getVideos(data) {
    return jfetch.get('/api/inspiration/video', data);
}

export function getArticlesBySearch(data) {
    return jfetch.get('/api/search/articles', data);
}

export function getRecipesBySearch(data) {
    return jfetch.get('/api/search/recipes', data);
}

export function getVideosBySearch(data) {
    return jfetch.get('/api/search/videos', data);
}