import React, { useState, useEffect, useRef } from 'react';
import { uploadFiles } from '../../../../apis/customer-fit-tool-service';

const FileUpload = props => {
	const [userId, setUserId] = useState(props.userId);
	const [cssClass, setCssClass] = useState(props.cssClass ?? '');
	const [fileList, setFileList] = useState(props.selectedValue?.files);
	const [uploadedFileList, setUploadedFileList] = useState(props.selectedValue?.uploadedFiles ?? []);
	const [enableUpload, setEnableUpload] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const inputRef = useRef(null);

	useEffect(() => {
		setEnableUpload(fileList && fileList.length > 0);

		props.onChange({ files: fileList, uploadedFiles: uploadedFileList });
	}, [fileList]);

	const handleChange = (e) => {
		if (e.target.files.length > 0) {
			let fullFileList = e.target.files;
			if (fileList && fileList.length > 0) {
				fullFileList = Array.from(fileList).concat(Array.from(e.target.files));
			}

			setFileList(fullFileList);
			setErrorMessage('');
		}
	};

	const handleUpload = () => {
			uploadInputFiles();
	};

	const handleSelect = () => {
		inputRef.current?.click();
	};

	const handleUploadRemove = (e, fileName) => {
		let fileNames = [...fileList];
		let updatedList = new DataTransfer();

		for (let i = 0; i < fileNames.length; i++) {
			let currentFile = fileNames[i];
			if (currentFile.name !== fileName) {
				updatedList.items.add(currentFile);
			}
		}

		setFileList(updatedList?.files);
	};

	const uploadInputFiles = () => {
		if (!fileList) {
			return;
		}

		let fileData = new FormData();
		let hasError = false;

		if (uploadedFileList.length + fileList.length > 3) {
			setErrorMessage('Too many files were selected.')
			return;
		}

		files.forEach((file, index) => {
			if (!validateFileSize(file.size)) {
				setErrorMessage('One or more files are too large.')
				hasError = true;
				return;
			}

			fileData.append(`files`, file, file.name);
		});

		if (hasError) return;

		uploadFiles(userId, fileData)
			.then(res => {
				if (res.success) {
					setFileList([]);
					setUploadedFileList([...uploadedFileList, ...fileList]);
					setEnableUpload(false);
					setErrorMessage('');

				} else {
					if (res.messages) {
						setErrorMessage(res.messages[0]);
					} else {
						setErrorMessage('An error occurred, we could not upload the files at this time.')
						console.log(res.exceptionMessage);
					}
					
				}
			})
			.catch(error => {
				console.log(error)
			});
	};

	const friendlyFileSize = (size) => {
		let reductionRate = 1;
		let label = 'B'

		if (size < 1000000) {
			reductionRate = 1000;
			label = 'KB'
		} else if (size < 1000000000) {
			reductionRate = 1000000;
			label = 'MB'
		} else if (size < 1000000000000) {
			reductionRate = 1000000000;
			label = 'GB'
		}
		return Math.round((size / reductionRate)*100)/100 + label
	}

	const validateFileSize = (fileSize) => {
		return fileSize <= 4194304;
	}

	const files = fileList ? [...fileList] : [];
	const uploadedFiles = uploadedFileList ? [...uploadedFileList] : [];

	return (
		<>
			<div className={`cft-file-upload ${cssClass}`}>
				{props.question.ShowLabel && <div >{props.question.Label ?? props.question.name}</div>}
				<ul className="pending-upload-list">
					{uploadedFiles && uploadedFiles.map((file, i) => (
						<li className="pending-upload" key={`uploaded-${i}`}>
							<span className="file-details"><strong>{file.name}</strong> ({friendlyFileSize(file.size)})</span> <span className="file-message"><span className="success">Uploaded</span></span>
						</li>
					))}
					{files && files.map((file, i) => (
						<li className="pending-upload" key={i}>
							<span className="file-details"><strong>{file.name}</strong> ({friendlyFileSize(file.size)})</span> <span className="file-message">{!validateFileSize(file.size) && <span className="error">File is too large</span>} {validateFileSize(file.size) && <span className="warning">Pending</span>}</span><a className="close" onClick={(e) => { handleUploadRemove(e, file.name) }}>&times;</a>
						</li>
					))}
				</ul>
				<div className="upload-controls">
					<button className="btn btn-basic btn-upload-select" onClick={handleSelect}>Select from your device</button> <button className="btn btn-outline" disabled={!enableUpload} onClick={handleUpload}>Upload</button>
				</div>
				<input type="file" ref={inputRef} onChange={handleChange} accept=".cad,.pdf,.jpeg,.jpg,.ma,.step" multiple style={{ display: 'none' }} />
				{enableUpload && <b>After selecting your file(s), please click the Upload button and wait for the confirmation to appear next to each file name</b>}
				<div className="info-text"><em>Supported file formats: .CAD, .PDF, .JPEG, .MA, .STEP</em></div>
				<div className="info-text"><em>Maximum file size is 4MB</em></div>
				{errorMessage && <div className="error">{errorMessage}</div>}
				{props.validationMessage && props.validationCssClass && <div className="error">{props.validationMessage}</div>}
			</div>
		</>
	);
}

export default FileUpload;