// https://preactjs.com/guide/v10/debugging/
// Must be the first import
if (process.env.NODE_ENV === 'development') {
    // Must use require here as import statements are only allowed
    // to exist at the top of a file.
    require("preact/debug");
}

// Full Vollrath
import Carousel from './components/carousels';
import './components/menu';
import ScrollTo from './components/scroll-to';
import GenusProducts from './components/genus-products-grid';
import FamilyGenus from './components/family-genus-grid';
import RecentProducts from './components/recent-products';
import Product from './components/product';
import Part from './components/part';
import ChatForm from './components/chat-form';
import Menu from './components/menu';
import WhereToBuy from './components/where-to-buy';
import FindAServiceAgent from './components/find-a-service-agent';
import SearchMenu from './components/search-menu';
import SearchLandingContainer from './components/search-landing';
import SearchContainer from './components/search';
import Clippy from './components/clipboard';
import Blog from './components/blog';
import InspirationGrid from './components/inspiration-grid';
import VideoLanding from './components/video-landing';
import InspirationSection from './components/inspiration-section';
import SocialShare from './components/social-share';
import Mosaic from './components/mosaic';
import PressGrid from './components/press-grid';
import './apis/setup';
import WishlistContainer from './components/wishlist-page';
import Forms from './components/forms';
import DownloadsLibraryContainer from './components/downloads-library';
import LazyLoader from './components/lazy-img';
import { loadInlineSVG } from './utils/';
/*import CookieConsent from './components/cookie-consent';*/
import Print from './components/print';
import VideoModal from './components/video-modal';
import Logout from './components/logout';
import InfoBanner from './components/info-banner';
import FilterableGridContainer from './components/filterable-grid';
import VirtualEventContainer from './components/virtual-event';
import CarouselWidgetContainer from './components/carousel-widget';
import ShoppingCart from './components/shopping-cart';
import ShoppingCartActions from './components/shopping-cart-actions';
//import Profile from './components/profile';
import Register from './components/register';
import UploadDocuments from './components/upload-documents';
import SubmitOrder from './components/submit-order-form';
import NewsletterSubscription from './components/newsletter-subscription';
import PhotoGallery from './components/photo-gallery';
import QualityControlContainer from './components/quality-control';
import ProductsForSaleContainer from './components/products-for-sale';
import MultiSelect from './components/multi-select';
import Dashboard from './components/dashboard';
import QuizContainer from './components/elearn-quiz';
import BeforeAfterSlider from './components/before-after-slider.js';
import SerVueCarouselContainer from './components/ser-vue-carousel';
import SerVueIngredientSlider from './components/ser-vue-ingredient-slider';
import PartsTool from './components/parts-tool';
import './react/components/customer-fit-tool/customer-fit-tool-app.jsx';

// lazy load inline svgs for site
loadInlineSVG('/inlinesvg');
//new CookieConsent();
//new InfoBanner();
new Menu();
new Carousel();
new ScrollTo();
new GenusProducts();
new FamilyGenus();
new RecentProducts();
new Product();
new Part();
new ChatForm();
new WhereToBuy();
new FindAServiceAgent();
new SearchMenu();
new SearchLandingContainer();
new Clippy();
new Blog();
new InspirationGrid();
new PressGrid();
new VideoLanding();
new InspirationSection();
new SocialShare();
new Mosaic();
new WishlistContainer();
new Forms();
new DownloadsLibraryContainer();
new Print();
new Logout();
new NewsletterSubscription();
new FilterableGridContainer();
new VirtualEventContainer();
new CarouselWidgetContainer();
new ShoppingCart();
new SubmitOrder();
//new Profile();
new Register();
new UploadDocuments();
new ShoppingCartActions();
new VideoModal();
new PhotoGallery();
new QualityControlContainer();
new SearchContainer();
new ProductsForSaleContainer();
new MultiSelect();
new Dashboard();
new QuizContainer();
new BeforeAfterSlider();
new SerVueCarouselContainer();
new SerVueIngredientSlider();
new PartsTool();

new LazyLoader({
    observer: {
        rootMargin: '100px'
    }
});


import '../site-js/site-main.js';
