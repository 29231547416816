import React from "react";

export default function Loader(props) {
    let classes = '';
    if (props.show) {
        classes = 'show';
    }
    return (
        <div className={`loader ${classes} ${props.className}`}>
            <div className="loader-spinner"></div>
        </div>
    );
}