// import GenusProductsContainer from '../react/components/genus-products-grid.jsx';
import { createElement } from 'react';
import { render } from 'react-dom';

export default class GenusProducts {
    constructor() {
        this.container = document.querySelector('.js-genus-products');

        if (this.container) {
            this.start();
        }
    }

    start() {
        const id = this.container.getAttribute('data-id');
        const isSales = (this.container.getAttribute('data-is-sales') === 'True');
        import('../react/components/genus-products-grid.jsx')
            .then(GenusProductsContainer => {
                render(createElement(GenusProductsContainer.default, { id, isSales }), this.container);
            });
    }
}