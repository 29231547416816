import { createElement } from 'react';
import { render } from 'react-dom';

export default class SerVueIngredientSliderContainer {
    constructor() {
        this.container = document.querySelector('.js-ser-vue-ingredient-slider');

        if (this.container) {
            this.start();
        }
    }

    start() {
        const
            imagesText = this.container.getAttribute('data-images-text'),
            ingredientsText = this.container.getAttribute('data-ingredients-text'),
            showTitle = this.container.getAttribute('data-show-title'),
            autoScroll = this.container.getAttribute('data-auto-scroll');

        import('../react/components/ser-vue-ingredient-slider.jsx')
            .then(SerVueCarousel => {
                render(createElement(SerVueCarousel.default, {
                    imagesText,
                    ingredientsText,
                    showTitle,
                    autoScroll
                }),
                    this.container);
            });
    }
}