class RecentProducts {
    constructor() {
        this.products = [];
        this.sync();
    }

    sync() {
        this.products = JSON.parse(localStorage['recentProductIds'] || '[]');
    }

    add(id) {
        // remove product if already in list
        const index = this.products.indexOf(id);
        if (index >= 0) this.products.splice(index, 1);
        // add to front of list
        this.products.unshift(id);
        localStorage['recentProductIds'] = JSON.stringify(this.products);
        return this.products;
    }

    delete(id) {
        const index = this.products.indexOf(id);
        if (index >= 0) this.products.splice(index, 1);
        localStorage['recentProductIds'] = JSON.stringify(this.products);
        return this.products;
    }
}

export default new RecentProducts();