import animateScrollTo from 'animated-scroll-to';

export default class ScrollTo {

    constructor() {
        this.links = document.querySelectorAll('.js-scroll-to');

        if (this.links.length) {
            this.addListeners();
        }
    }

    addListeners() {
        this.selectBind = this.select.bind(this);
        for (let i = 0; i < this.links.length; i++) {
            this.links[i].addEventListener('click', this.selectBind);
        }
    }

    select(e) {
        e.preventDefault();
        let target = e.currentTarget || e.target;
        while (!target.getAttribute('href')) {
            target = target.parentElement;
        }

        animateScrollTo(document.getElementById(target.getAttribute('href').replace('#', '')));
    }

    destroy() {
        if (this.links.length) {
            for (let i = 0; i < this.links.length; i++) {
                this.links[i].removeEventListener('click', this.selectBind);
            }
        }
    }

}