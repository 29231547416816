import React, { Component } from 'react';
import { getProductsByKeyword, getClassificationsByKeyword, getDocuments, getVideos } from '../../apis/search';
import debounce from 'lodash/debounce';
import toast from '../../components/toast';

class SearchMenuBase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            q: '',
            isOpen: false,
            keywordItems: [],
            productItems: [],
            documentItems: [],
            videoItems: [],
            isLoading: false,
            quickLinks: props.links,
            isWindowUnloading: false,
            category: ''
        };

        this.toggleSearch = this.toggleSearch.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.loadDebounce = debounce(this.load.bind(this), 500);

        this.searchInput = React.createRef();
        this.searchDrawer = React.createRef();

        window.addEventListener('beforeunload', e => {
            this.setState({
                isWindowUnloading: true
            });
        });
    }

    toggleSearch() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.loadDebounce();
        });
    }

    handleFocus(e) {
        this.setState({
            isOpen: true
        });
    }

    handleBlur(e) {
        this.setState({
            isOpen: false
        });
    }

    handleFormSubmit(e) {
        e.stopPropagation();
    }

    load() {
        if (this.state.q && this.state.q.length > 2) {
            const params = {
                q: this.state.q,
                page: 1,
                pageSize: 5
            };

            this.setState({
                isLoading: true,
                productItems: [],
                keywordItems: [],
                documentItems: [],
                videoItems: []
            });

            if (this.productPromise) this.productPromise.controller.abort();
            if (this.classificationPromise) this.classificationPromise.controller.abort();
            if (this.documentsPromise) this.documentsPromise.controller.abort();
            if (this.videoPromise) this.videoPromise.controller.abort();

            // Products
            this.productPromise = getProductsByKeyword(params);
            this.productPromise.then(data => {
                this.setState({
                    productItems: data.records
                });
                return data;
            })
                .catch(error => {
                    console.log(error);
                    if (error.name === 'AbortError' || this.state.isWindowUnloading) return error;
                    toast.error('There was an error getting products');
                    return error;
                });

            // Classifications
            this.classificationPromise = getClassificationsByKeyword(params);
            this.classificationPromise.then(data => {
                this.setState({
                    keywordItems: data.records
                });
                return data;
            })
                .catch(error => {
                    console.log(error);
                    if (error.name === 'AbortError' || this.state.isWindowUnloading) return error;
                    toast.error('There was an error getting product families');
                    return error;
                });

            // Documents
            this.documentsPromise = getDocuments(params);
            this.documentsPromise.then(data => {
                this.setState({
                    documentItems: data.records
                });
                return data;
            })
                .catch(error => {
                    console.log(error);
                    if (error.name === 'AbortError' || this.state.isWindowUnloading) return error;
                    toast.error('There was an error getting documents');
                    return error;
                });

            // Videos
            this.videosPromise = getVideos(params);
            this.videosPromise.then(data => {
                this.setState({
                    videoItems: data.records
                });
                return data;
            })
                .catch(error => {
                    console.log(error);
                    if (error.name === 'AbortError' || this.state.isWindowUnloading) return error;
                    toast.error('There was an error getting videos');
                    return error;
                });

            Promise.all([this.productPromise, this.classificationPromise, this.documentsPromise])
                .finally(() => {
                    this.setState({
                        isLoading: false
                    });
                });
        }
        else {
            this.setState({
                productItems: [],
                keywordItems: []
            });
        }
    }
}

export default SearchMenuBase;