import React, { Component } from "react";
import wishlist from '../../components/wishlist';
import toast from '../../components/toast';

class ProductWishlistBtn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isInWishlist: false,
            isLoading: false
        };

        this.handleWishlistClick = this.handleWishlistClick.bind(this);
    }

    componentWillMount() {
        wishlist.hasProduct(this.props.id).then(result => this.setState({
            isInWishlist: result
        }));
    }

    handleWishlistClick(e) {
        wishlist.hasProduct(this.props.id).then(result => {
            this.setState({
                isInWishlist: result,
                isLoading: true
            });
            if (result) {
                wishlist.delete(this.props.id)
                    .then(data => {
                        toast.success('Removed from your wish list');
                        this.setState({
                            isInWishlist: false
                        });
                    })
                    .catch(error => {
                        toast.error('There was an error trying to remove this product from your wish list');
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false
                        });
                    });
            }
            else {
                wishlist.add(this.props.id)
                    .then(data => {
                        toast.success('Added to your wish list');
                        this.setState({
                            isInWishlist: true
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        toast.error('There was an error trying to add this product to your wish list');
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false
                        });
                    });
            }
        });
    }

    render() {
        let wishlistBtn = <button class={`btn btn-outline-alt ${this.props.btnClass}`} type="button" onClick={this.handleWishlistClick} disabled={this.state.isLoading}>
            <svg className="icon icon-heart"><use xlinkHref="#icon-heart"></use></svg>
            <span>Add to Wish List</span>
        </button>;

        if (this.state.isInWishlist) {
            wishlistBtn = <button class={`btn btn-outline-alt ${this.props.btnClass}`} type="button" onClick={this.handleWishlistClick} disabled={this.state.isLoading}>
                <svg className="icon icon-heart-filled"><use xlinkHref="#icon-heart-filled"></use></svg>
                <span>Added to Wish List</span>
            </button>;
        }

        return wishlistBtn;
    }
}

export default ProductWishlistBtn;