// https://github.com/twobin/react-lazyload/blob/master/src/index.jsx
import React, { Component, createRef } from 'react';

function createObserver(callback, options) {
    return new IntersectionObserver(callback, options);
}

class LazyLoad extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };

        this.checkIntersection = this.checkIntersection.bind(this);

        this.placeholder = createRef();
    }

    componentDidMount() {
        this.observe();
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextState.visible) this.unobserve();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.visible && !prevState.visible) this.props.onVisible();
    }

    componentWillUnmount() {
        this.unobserve();
    }

    observe() {
        if (!this.observer) this.observer = createObserver(this.checkIntersection, { rootMargin: this.props.rootMargin, threshold: this.props.threshold });
        this.observer.observe(this.placeholder.current);
    }

    unobserve() {
        if(this.placeholder.current) this.observer.unobserve(this.placeholder.current);
    }

    checkIntersection(entries, observer) {
        entries.forEach(entry => {
            // console.log(entry);
            if (entry.isIntersecting) {
                this.setState({
                    visible: true
                });
            }
        });
    }

    render() {
        let placeholder;

        if (!this.state.visible) {
            placeholder = this.props.placeholder;
            if (!placeholder) {
                placeholder = <div className="lazyload-placeholder" style={`height: ${this.props.height}`} ref={this.placeholder}></div>;
            }
        }

        return this.state.visible ? this.props.children : placeholder;
    }
}

LazyLoad.defaultProps = {
    rootMargin: '0px',
    threshold: 0,
    height: '0px',
    onVisible: function () { }
};

export default LazyLoad;