import { createElement } from 'react';
import { render } from 'react-dom';
import Magnific from '../react/components/magnific.jsx';
// import VideoGridContainer from '../react/components/video-grid.jsx';

export default class VideoLanding {
    constructor() {
        this.featuredVideoLink = document.querySelectorAll('.js-featured-video');
        this.openFeaturedVideo = this.openFeaturedVideo.bind(this);
        this.root = document.querySelector('.js-video-grid-root');

        if (this.featuredVideoLink.length) {
            this.magnificRoot = document.createElement('div');
            document.body.appendChild(this.magnificRoot);
            this.modalProps = {};
            this.renderMagnific();
            this.addListeners();
        }

        if (this.root) {
            this.render();
        }
    }

    addListeners() {
        [...this.featuredVideoLink].forEach(link => {
            link.addEventListener('click', this.openFeaturedVideo);
        });
    }

    openFeaturedVideo(e) {
        e.preventDefault();
        let target = e.target;
        while (target.tagName !== 'A') {
            target = target.parentElement;
        }
        this.modalProps = {
            items: [
                {
                    type: target.href.indexOf('.mp4') >= 0 ? 'video' : 'iframe',
                    src: target.href
                }
            ],
            show: true
        };
        this.renderMagnific();
    }

    renderMagnific() {
        render(createElement(Magnific, this.modalProps), this.magnificRoot);
    }

    render() {
        const categories = JSON.parse(this.root.getAttribute('data-categories') || "[]"),
            tags = JSON.parse(this.root.getAttribute('data-tags') || "[]");

        import('../react/components/video-grid.jsx')
            .then(VideoGridContainer => {
                render(createElement(VideoGridContainer.default, { categories, tags }), this.root);
            });
    }
}