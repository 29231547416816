import { createElement } from 'react';
import { render } from 'react-dom';


export default class PartsTool {
    constructor() {
        this.container = document.querySelector('.js-parts-tool');

        if (this.container) {
            this.start();
        }
    }

    start() {
      
        import('../react/components/parts-tool.jsx')
            .then(PartsTool => {
                render(createElement(PartsTool.default), this.container);
            });
    }
}