import React, { Component, Suspense, lazy } from "react";
import Lazyload from './lazyload.jsx';
import CSSTransition from 'react-transition-group/CSSTransition';

class VideoItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imageVisible: false
        };

        this.handleLazyloadVisible = this.handleLazyloadVisible.bind(this);
    }

    handleLazyloadVisible() {
        this.setState({
            imageVisible: true
        });
    }

    render() {

        const isWiden = this.props.image.includes('widen');
        const imageUrl = isWiden ? this.props.image : this.props.image.replace('~', '').split('?')[0];

        let addToProjectBtn = '';

        if (this.props.quickAdd && (window.location.host == ("localhost:44335") || window.location.hostname.includes("vollrathfoodservice.com"))) {
            const QuickAdd = lazy(() => import("../../../site-js/react/components/project-builder/quick-add.jsx"));
            addToProjectBtn = <Suspense><QuickAdd videoNodeId={this.props.nodeId} videoTitle={this.props.title} /></Suspense>;
        }

        return (
            <div className={this.props.colClass}>
                <section className="card">
                    <div className="card-media video">
                        <CSSTransition in={this.state.imageVisible} timeout={250} classNames="fade">
                            <Lazyload height="100%" rootMargin="100px" onVisible={this.handleLazyloadVisible}>
                                <a href={this.props.url} onClick={this.props.onClick} target="_blank" data-index={this.props.index}>
                                    <img srcSet={isWiden ? imageUrl : `${imageUrl}?width=440&resizemode=force 1x, ${imageUrl}?width=880&resizemode=force 2x`}  alt={this.props.imageAlt} alt={this.props.title} />
                                    <svg className="icon icon-play card-media-play"><use xlinkHref="#icon-play"></use></svg>
                                </a>
                            </Lazyload>
                        </CSSTransition>
                    </div>
                    <h3 className="card-title">{this.props.title}</h3>
                    <span className="card-desc">Run Time: {this.props.time}</span>
                    {this.props.quickAdd ? addToProjectBtn : null}
                </section>
            </div>
        );
    }
}

VideoItem.defaultProps = {
    colClass: 'col-sm-6'
};

export default VideoItem;