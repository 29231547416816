import jfetch from '@jontetz/jfetch';

/**
 * Get an array of products
 * @param {Array} ids
 */
export function getProducts(ids) {
    console.log(ids);
    return jfetch.get(`/api/product/batch?ids=${ids.join(',')}`);
}

export function getPartProducts(id) {
    return jfetch.get(`/api/product/partproducts?id=${id}`);
}

export function postAskAQuestion(params) {
    return jfetch.post(`/api/product/askaquestion`, params);
}

export function getPartsByProduct(params) {
    return jfetch.post(`/api/product/getpartsbyproductanddate`, params);
}

export function getProductsWithChemicalCodeByItemNumber(params) {
    return jfetch.get(`/api/product/getproductswithchemicalcodebyitemnumber`, params);
}