
import { createElement } from 'react';
import { render } from 'react-dom';

export default class NewsletterSubscription {
    constructor() {
        this.container = document.querySelector('.js-newsletter');

        if (this.container) {
            this.start();
        }
    }

    start() {
        const title = this.container.getAttribute('data-title'),
            copy = this.container.getAttribute('data-copy'),
            newsletter = this.container.getAttribute('data-newsletter'),
            generic = this.container.getAttribute('data-generic');

        import('../react/components/newsletter.jsx')
            .then(Newsletter => {
                render(createElement(Newsletter.default, { title, copy, newsletter, generic }), this.container);
            });
    }
}