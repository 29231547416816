import React, { useState, useEffect } from 'react';

const Text = props => {
	const [htmlId, setHtmlId] = useState(`question-${props.question.id}`);
	const [selectedValue, setSelectedValue] = useState();
	const [cssClass, setCssClass] = useState(props.cssClass ?? '');

	useEffect(() => {
		setSelectedValue(props.selectedValue)
	}, [props.selectedValue]);

	const handleChange = (e) => {
		setSelectedValue(e.target.value);
		props.onChange(e.target.value);
	};
	
	return (
		<>
			<div className={`cft-text ${cssClass} ${props.validationCssClass}`}>
				{props.question.showLabel && <label for={htmlId} >{props.question.label ?? props.question.name}{`${props.question.isRequired ? ' *' : ''}`}</label>}
				{props.validationMessage && props.validationCssClass && <span className="validation-message">{props.validationMessage}</span>}
				<input type="text" className={`form-control ${props.validationCssClass}`} id={htmlId} placeholder={props.question.placeholderText} value={selectedValue} onInput={handleChange} />
			</div>
		</>
	);
}

export default Text;