import React, { useState, useEffect } from 'react';

const LoaderBar = props => {

	const [animate, setAnimate] = useState('');

	useEffect(() => {
		setAnimate('animate');
	}, []);



	return (
		<>
			<div className="loader-bar-container">
				{props.question.showLabel && <div><strong>{props.question.label ?? props.question.name}</strong></div>}
				<div className="progress-bar">
					<div className={`tracker ${animate}`}></div>
				</div>
			</div>
		</>
	);
}

export default LoaderBar;