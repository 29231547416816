import SearchMenuContainer from '../react/components/search-menu.jsx';
import SearchMenuMobile from '../react/components/search-menu-mobile.jsx';
import { createElement } from 'react';
import { render } from 'react-dom';

export default class SearchMenu {
    constructor() {
        this.container = document.querySelector('.js-search-menu-root');
        this.mobileContainer = document.querySelector('.js-mobile-search-root');

        if (this.container) {
            this.start();
        }
       
    }

    start() {
        const links = JSON.parse(this.container.getAttribute('data-links'));
        render(createElement(SearchMenuContainer, { links }), this.container);
        render(createElement(SearchMenuMobile, { links }), this.mobileContainer);
    }
}