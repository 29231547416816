

export default class BeforeAfterSlider {
    constructor() {
        this.sliderContainer = document.querySelector(".slider-container");

        if (this.sliderContainer) {
            this.start();
        }



    }

    start() {
        let sliderContainer = document.querySelector(".slider-container");
        let afterImage = sliderContainer.querySelector(".after");
        let comparissonSlider = sliderContainer.querySelector(".slider");
        let compInputRange = sliderContainer.querySelector("input");

        comparissonSlider.addEventListener("mousedown", slideReady);
        comparissonSlider.addEventListener("touchstart", slideReady);
        compInputRange.addEventListener("input", inputToSlide);

        function slideReady(e) {
            e.preventDefault();
            window.addEventListener("mousemove", slideMove);
            window.addEventListener("touchmove", slideMove);
            window.addEventListener("mouseup", slideFinish);
            window.addEventListener("touchend", slideFinish);
        }

        function slideFinish() {
            window.removeEventListener("mousemove", slideMove);
            window.removeEventListener("touchmove", slideMove);
            window.removeEventListener("mouseup", slideFinish);
            window.removeEventListener("touchend", slideFinish);
        }

        function slideMove(event) {
            let position = getCursorPos(event);
            if (position < 0) position = 0;
            if (position > sliderContainer.offsetHeight) position = sliderContainer.offsetHeight;
            slide(position);
        }

        function getCursorPos(event) {
            event = event || window.event;
            let boundingRect = afterImage.getBoundingClientRect();
            let positionY = event.pageY - boundingRect.top;
            positionY = positionY - window.pageYOffset;
            return positionY;
        }

        function slide(position) {
            afterImage.style.height = `${(position / sliderContainer.offsetHeight) * 100}%`;
            comparissonSlider.style.top = `${(afterImage.offsetHeight / sliderContainer.offsetHeight) * 100}%`;
            compInputRange.value = (position / sliderContainer.offsetHeight) * 100;
        }

        function inputToSlide() {
            afterImage.style.height = `${compInputRange.value}%`;
            comparissonSlider.style.top = `${compInputRange.value}%`;
        }
    }

}