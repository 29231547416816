// import SearchLanding from '../react/pages/search-landing.jsx';
import { createElement } from 'react';
import { render } from 'react-dom';

export default class SearchLandingContainer {
    constructor() {
        this.container = document.querySelector('.js-search-root');

        if (this.container) {
            this.start();
        }
    }

    start() {      
        import('../react/pages/search-landing.jsx')
            .then(SearchLanding => {
                render(createElement(SearchLanding.default), this.container);
            });
    }
}