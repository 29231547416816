import React, { useState, useEffect } from 'react';
import Text from './input-fields/text.jsx';
import TextArea from './input-fields/text-area.jsx';
import Dropdown from './input-fields/dropdown.jsx';
import RadioButton from './input-fields/radio-button.jsx';
import ImageRadioButton from './input-fields/image-radio-button.jsx';
import RadioSlider from './input-fields/radio-slider.jsx';
import FileUpload from './input-fields/file-upload.jsx';
import LoaderBar from './input-fields/loader-bar.jsx';

const Question = props => {
	const [defaultValue, setDefaultValue] = useState("");
	const [selectedValue, setSelectedValue] = useState(props.selectedValue);
	const [isValid, setIsValid] = useState(false);
	const [validationCssClass, setValidationCssClass] = useState('');
	const [validationMessage, setValidationMessage] = useState('');

	useEffect(() => {
		validationChecks(props.selectedValue);

		let previouslyEnteredValue;
		props.userSelections?.find(step => previouslyEnteredValue = step.questions.find(question => question.name == props.question.name)?.value);

		let systemDefault;
		systemDefault = '';

		if (previouslyEnteredValue) {
			setDefaultValue(previouslyEnteredValue);
		} else if (props.currentContact && props.currentContact[props.question.name]) {
			setDefaultValue(props.currentContact[props.question.name]);
		} else if (systemDefault) {
			setDefaultValue(systemDefault);
		}
	}, []);

	useEffect(() => {
		if (defaultValue && (props.selectedValue == null || props.selectedValue == undefined)) {
			setSelectedValue(defaultValue);
			handleChange(defaultValue);
		}
	}, [defaultValue]);

	useEffect(() => {
		props.onValidate(isValid);
	}, [isValid]);

	useEffect(() => {
		setValidationCssClass(props.showValidation && !isValid ? 'error' : '');
	}, [props.showValidation, isValid]);

	const handleChange = (selectedValue) => {

		validationChecks(selectedValue);

		let answer = props.question.answers.filter((item) => item.value === selectedValue);
		props.onChange({questionId: props.question.id, name: props.question.name, value: selectedValue, pointValue: answer[0]?.pointValue ?? 0});
	};

	const validationChecks = (value) => {
		let valid = true;
		let valMessage;

		//Required Field Validator
		if (props.question.isRequired) {
			valid = value ? true : false;
			valMessage = 'Please fill out the required field';
		}

		//Custom Validator
		let custRegexExp;
		switch (props.question.specialValidationTypeId) {
			case 1: //Number Validator
				custRegexExp = /^\d+$/;
				valMessage = 'Please enter a valid number';
				break;
			case 2: //Email Validator
				custRegexExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
				valMessage = 'Please enter a valid email address';
				break;
			case 3: //Phone Validator
				custRegexExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
				valMessage = 'Please enter a valid phone number';
				break;
		}

		if (custRegexExp) {
			valid = custRegexExp.test(value);
		}

		if (value?.files?.length > 0) {
			valid = false;
			valMessage = 'There are pending file uploads';
		}

		setValidationMessage(valMessage);
		setIsValid(valid);
	};
	
	return (
		<>
			{(() => {
				switch (props.question.questionTypeId) {
					case 1: //text
						return <Text question={props.question} selectedValue={selectedValue} cssClass={props.cssClass} validationCssClass={validationCssClass} validationMessage={validationMessage} onChange={handleChange} />
					case 2: //textarea
						return <TextArea question={props.question} selectedValue={selectedValue} cssClass={props.cssClass} validationCssClass={validationCssClass} validationMessage={validationMessage} onChange={handleChange} />
					case 3: //dropdown
						return <Dropdown question={props.question} selectedValue={selectedValue} cssClass={props.cssClass} validationCssClass={validationCssClass} validationMessage={validationMessage} onChange={handleChange} />
					case 4: //radiobutton
						return <RadioButton question={props.question} selectedValue={selectedValue} cssClass={props.cssClass} validationCssClass={validationCssClass} validationMessage={validationMessage} onChange={handleChange} />
					case 5: //imageradiobutton
						return <ImageRadioButton question={props.question} selectedValue={selectedValue} cssClass={props.cssClass} validationCssClass={validationCssClass} validationMessage={validationMessage} onChange={handleChange} />
					case 6: //radioslider
						return <RadioSlider question={props.question} selectedValue={selectedValue} cssClass={props.cssClass} validationCssClass={validationCssClass} validationMessage={validationMessage} onChange={handleChange} />
					case 7: //fileupload
						return <FileUpload question={props.question} userId={props.userId} selectedValue={selectedValue} cssClass={props.cssClass} validationCssClass={validationCssClass} validationMessage={validationMessage} onChange={handleChange} />
					case 8: //loaderbar
						return <LoaderBar question={props.question} selectedValue={selectedValue} cssClass={props.cssClass} onChange={handleChange} />
				}
			})()}
		</>
	);
}

export default Question;