//import InspirationGridContainer from '../react/components/inspiration-grid.jsx';
import Press from '../react/components/press-item.jsx';
import { getPressReleases, getPressReleasesBySearch } from '../apis/press';
import { createElement } from 'react';
import { render } from 'react-dom';

export default class PressGrid {
    constructor() {
        this.root = document.querySelector('.js-press-grid-root');

        if (this.root) {
            this.render();
        }
    }

    render() {
        const categories = JSON.parse(this.root.getAttribute('data-categories') || "[]");
        let api = getPressReleases, search = getPressReleasesBySearch;
        import('../react/components/inspiration-grid.jsx')
            .then(InspirationGridContainer => {
                render(createElement(InspirationGridContainer.default, { categories, api, search, item: Press }), this.root);
            });
    }
}