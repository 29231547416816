import React, { Component } from "react";

function Press(props) {
    const imageUrl = props.image.replace('~', '').split('?')[0];
    return (
        <div className={props.colClass}>
            <article className="card">
                <h3 className="card-title"><a href={props.pageUrl}>{props.title}</a></h3>
                <p className="card-desc">{props.shortDescription}</p>
                <a className="card-link" href={props.pageUrl}>Read More ></a>
            </article>
        </div>
    );
}

Press.defaultProps = {
    colClass: 'col-lg-12'
};

export default Press;