import jfetch from '@jontetz/jfetch';

/**
 * Aync load a JavaScript file
 * @param {String} src
 */
export function loadScript(src) {
    let promise = new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = function () {
            resolve();
        };
        script.onerror = function () {
            reject(new Error('Failed to load script ' + src));
        };
        document.head.appendChild(script);
    });

    return promise;
}

/**
 * See if an element is within the bounds of the viewport
 * @param {Element} el
 * @param {Number} padding
 */
export function isElementInView(el, padding = 0) {
    var elRect = el.getBoundingClientRect();

    if (elRect.top - padding < window.innerHeight && elRect.bottom + padding >= 0) {
        return true;
    }

    return false;
}

/**
 * See if an element is within the viewport at all
 * @param {Element} el
 */
export function isElementPartiallyInView(el) {
    var elRect = el.getBoundingClientRect();

    if (elRect.top < window.innerHeight && elRect.top + elRect.height > 0) {
        return true;
    }

    return false;
}

/**
 * Trigger a custom event on an element
 * @param {Element} el
 * @param {String} eventName
 */
export function trigger(el, eventName) {
    if (!el) return;

    // Create the event.
    var event = document.createEvent('Event');

    event.initEvent(eventName, true, true);

    // target can be any Element or other EventTarget.
    el.dispatchEvent(event);
}

/**
 * Get the current query string paramters as key/value pairs
 * */
export function getUrlParams() {
    var urlParams,
        match,
        pl = /\+/g,  // Regex for replacing addition symbol with a space
        search = /([^&=]+)=?([^&]*)/g,
        decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); },
        query = window.location.search.substring(1);

    urlParams = {};
    while (match = search.exec(query))
        urlParams[decode(match[1])] = decode(match[2]);

    return urlParams;
}

/**
 * Uses the current page url and replaces/adds a page value
 * */
export function generatePaginationHref(page) {
    const hasSearch = location.search.length > 1,
        pageParam = page > 1 ? `page=${page}` : '';
    if (!hasSearch) return `${location.pathname}?${page > 1 ? pageParam : ''}`;
    return `${location.pathname}${location.search.length > 1 && page > 1 ? location.search.replace(/[page=]+[0-9]/, pageParam) : ''}`;
}

export function transformBytes(bytes, si) {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    var u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
}

/**
 * Provided a url to an svg, loads and inserts into the DOM
 * @param {String} src
 * */
export function loadInlineSVG(src) {
    jfetch.get(src).then(html => {
        const div = document.createElement('div');
        div.innerHTML = html;
        document.getElementsByTagName('body')[0].appendChild(div);
    });
}

export function nameSorter(a, b) {
    // from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
    var nameA = a.Name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.Name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    // names must be equal
    return 0;
}

/**
 * Guesses whether a string contains a metric dimension label
 * @param {String} str String with units
 * @returns {Boolean} isMetric
 */
export function isMetricGuess(str) {
    if (str.toLowerCase().indexOf("(cm)") > 1) return true;
    else if (str.toLowerCase().indexOf("(mm)") > 1) return true;
    else if (str.toLowerCase().indexOf("(kg)") > 1) return true;
    else if (str.toLowerCase().indexOf("(l)") > 1) return true;
    else if (str.toLowerCase().indexOf("(ml)") > 1) return true;
    else return false;
}

/**
 * Takes a string and dash separates the spaces
 * @param {String} str
 * */
export function toClassName(str) {
    if (!str) return str;
    return str.replace(/([^a-zA-Z0-9]+)/g, '-').toLowerCase();
}

/**
 * Takes an object of key value pairs and appends it to a url
 * @param {String} url
 * @param {Object} params
 * */
export function addParamsToUrl(url, params) {
    if (!url || Object.entries(params).length === 0) return url;
    const formattedParams = Object.entries(params).map(p => { return `${p[0]}=${encodeURIComponent(p[1])}`; });
    return (url.indexOf('?') >= 0 ? url + '&' : url + '?') + formattedParams.join('&');
}

//https://stackoverflow.com/a/34064434
export function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
}

/**
 * Async download file on browser
 * @param {String} src
 * @param {String} name
 */
export async function downloadImg(src, name) {
    const img = await fetch(src);
    const imgBlob = await img.blob();
    const imageUrl = URL.createObjectURL(imgBlob);

    const baseImgUrl = name.replace(' ', '_');
    const imgUrl = src.split('jpeg')[0];
    const fullUrl = `${baseImgUrl}_${imgUrl}`;

    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = `${fullUrl}.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

/**
 * Allows easy storage and updates for a dictionary-style data structure
 * @param {Array} collection
 * @param {Object} key
 * * @param {Object} value
 * */
export function updateDictionary(collection, key, value) {
    let tempCollection = collection ? [...collection] : [];
    let index = tempCollection.findIndex(item => item.key === key);
    if (index > -1) {
        tempCollection[index].value = value;
    } else {
        tempCollection.push({ key, value });
    }

    return tempCollection;
}