import React, { useState, useEffect } from 'react';

const ImageRadioButton = props => {
	const [htmlId, setHtmlId] = useState(`question-${props.question?.id}`);
	const [answers, setAnswers] = useState(props.question?.answers);
	const [selectedValue, setSelectedValue] = useState();
	const [cssClass, setCssClass] = useState(props.cssClass ?? '');

	useEffect(() => {
		setSelectedValue(props.selectedValue)
	}, [props.selectedValue]);

	const handleChange = (e) => {
		setSelectedValue(e.target.value);
		props.onChange(e.target.value);
	};
	
	return (
		<>
			{props.question &&
				<div className={`cft-image-radio-button ${cssClass} ${props.validationCssClass}`}>
					{props.validationMessage && props.validationCssClass &&
						<div className="row">
							<div className="col">
								<span className="validation-message">{props.validationMessage}</span>
							</div>
						</div>
					}
					<div className="row">
						{answers && answers.map((item, index) => {
							return (
								<>
									<div className={`col-lg-${Math.max(Math.ceil(12 / answers.length), 3)} ${props.validationCssClass} col-6`}>
										<label>
											<input type="radio" value={item.value} name={htmlId} checked={selectedValue === item.value} onBlur={handleChange} alt={item.value} />
											<img src={item.imageUrl} />
											<div className="btn-label" >{item.value}</div>
										</label>
									</div>
								</>
							);
						})}
					</div>
				</div>
			}
		</>
	);
}

export default ImageRadioButton;