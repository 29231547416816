import React, { Component } from 'react';

class SearchMenuBase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            q: '',
            isOpen: false,
            isWindowUnloading: false
        };

        this.toggleSearch = this.toggleSearch.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);

        this.searchInput = React.createRef();

        window.addEventListener('beforeunload', e => {
            this.setState({
                isWindowUnloading: true
            });
        });
    }

    toggleSearch() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleFocus(e) {
        this.setState({
            isOpen: true
        });
    }

    handleBlur(e) {
        this.setState({
            isOpen: false
        });
    }

    handleFormSubmit(e) {
        e.stopPropagation();
    }   
   
}

export default SearchMenuBase;