import jfetch from '@jontetz/jfetch';

export function getProductClassificationChildren(id, data) {
    return jfetch.get(`/api/productclassification/children?id=${id}`, data);
}

export function getProductClassificationSiblings(id, data) {
    return jfetch.get(`/api/productclassification/siblings?id=${id}`, data);
}

export function getProductClassificationProducts(id, data) {
    return jfetch.get(`/api/productclassification/products?id=${id}`, data);
}

export function getProductClassificationRelatedGenus(id) {
    return jfetch.get(`/api/productclassification/relatedgenus?id=${id}`);
}