import { createElement } from 'react';
import { render } from 'react-dom';

export default class DownloadsLibraryContainer {
    constructor() {
        this.container = document.querySelector('.js-downloads-root');

        if (this.container) {
            this.start();
        }
    }

    start() {
        const noDocs = this.container.getAttribute('data-no-documents'),
            searchMsg = this.container.getAttribute('data-search-message'),
            categoryMsg = this.container.getAttribute('data-category-message'),
            docCodes = JSON.parse(this.container.getAttribute('data-doc-type'));

        import('../react/pages/downloads-library.jsx')
            .then(DownloadsLibrary => {
                render(createElement(DownloadsLibrary.default, {noDocs, searchMsg, categoryMsg, docCodes}), this.container);
            });
    }
}