import React, { Component } from "react";
import Section from './section.jsx';
import Blog from './blog-item.jsx';
import { getRecipes } from '../../apis/inspiration';

class RecipesSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            isLoading: true
        };
    }

    componentDidMount() {
        getRecipes({
            page: 1,
            pageSize: 3,
        })
            .then(data => {
                this.setState({
                    items: data.Records,
                    isLoading: false
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    isLoading: false
                });
            });
    }

    render() {
        if (this.state.items.length || this.state.isLoading) {
            return (
                <Section titleBold={this.props.titleBold} link={this.props.link} linkText={this.props.linkText}>
                    <div className="row">
                        {this.state.items.map((blog, index) => (
                            <Blog key={index} {...blog} />
                        ))}
                    </div>
                </Section>
            );
        }

        return null;
    }
}

export default RecipesSection;